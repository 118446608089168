import axios from 'axios';

const serviceDiscord = axios.create({
  baseURL: `${process.env.VUE_APP_URL_DISCORD}/webhooks`,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const report = ({ title, description, color = '14177041' }) => {
  serviceDiscord.post(`/${process.env.VUE_APP_ID_DISCORD}/${process.env.VUE_APP_TOKEN_DISCORD}`, {
    embeds: [
      {
        title,
        description,
        color
      }
    ]
  });
};

export {
  report
};

// example request for discord report incidents
// import { report } from '@/helpers/apis/discord';
// report({
//   title: 'Error endTime function in prepago view',
//   description: `**Environment**: ${process.env.NODE_ENV} \n **Message**: Esto es una prueba mk`,
//   color: '' - si no se envia es rojo por defecto si no lo quieren rojo mandar azul o verde
// });
// 14177041 = rojo
// 7506394 = azul-morado
// 1102135 = verde
