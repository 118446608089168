var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      !_vm.validateMareaSpecial() &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      !_vm.reservationInfo.decoration &&
      _vm.experiencePay <= 0 &&
      !_vm.params.activeExperiencies &&
      !_vm.reservationInfo.isEvent
    )?_c('div',{staticClass:"total-container"},[_c('TooltipTYC',{on:{"showTerms":function($event){return _vm.$emit('showTerms')}}}),_c('p',{staticClass:"final-total-right"},[_vm._v(_vm._s(_vm.$t("total"))+":")]),(!_vm.getPayReservation() && !_vm.getPayReservationBoy() && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.params.purchaseCost * _vm.reservationInfo.people + _vm.getExperiencesValue()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && !_vm.getPayReservationBoy() && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(!_vm.getPayReservation() && !_vm.getPayReservationBoy() && _vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationFixedValue(),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && _vm.getPayReservationBoy() && _vm.reservationInfo.boy > 0 && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.adult + _vm.getPayReservationBoyValue() * _vm.reservationInfo.boy + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && _vm.getPayReservationBoy() && _vm.reservationInfo.boy === 0)?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e()],1):_vm._e(),(
      !_vm.validateMareaSpecial() &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      !_vm.reservationInfo.decoration &&
      _vm.experiencePay <= 0 &&
      _vm.params.activeExperiencies &&
      !_vm.reservationInfo.isEvent
    )?_c('div',{staticClass:"total-container"},[_c('TooltipTYC',{on:{"showTerms":function($event){return _vm.$emit('showTerms')}}}),_c('p',{staticClass:"final-total-right"},[_vm._v(_vm._s(_vm.$t("total"))+":")]),(!_vm.getPayReservation() && !_vm.getPayReservationBoy() && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.params.purchaseCost * _vm.reservationInfo.people + _vm.getExperiencesValue()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && !_vm.getPayReservationBoy() && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(!_vm.getPayReservation() && !_vm.getPayReservationBoy() && _vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationFixedValue(),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && _vm.getPayReservationBoy() && _vm.reservationInfo.boy > 0 && !_vm.getPayReservationFixed())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.adult + _vm.getPayReservationBoyValue() * _vm.reservationInfo.boy + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservation() && _vm.getPayReservationBoy() && _vm.reservationInfo.boy === 0)?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people + _vm.getExperiencesValue() + _vm.getTip3Cord()),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }