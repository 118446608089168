<template>
    <div
        class="total-container"
        v-if="reservationTotal.typeReservation === 'Rumba' || reservationTotal.typeReservation === 'Cena' || reservationTotal.typeReservation === 'Pombo' || reservationTotal.typeReservation === 'Brunch'"
    >
        <TooltipTYC @showTerms="$emit('showTerms')"/>
        <p class="final-total-right">{{$t('total')}}:</p>
        <p class="final-total-right" v-if="reservationTotal.typeReservation === 'Cena'">
            {{ ((reservationTotal.payDetails.adult * reservationTotal.people) + (reservationTotal.payDetails.cover * reservationTotal.people))
            | currency('$ ', 0, { thousandsSeparator: ',' }) }}
        </p>
        <p class="final-total-right" v-if="reservationTotal.typeReservation === 'Rumba'">
            {{ ((reservationTotal.payDetails.adult * reservationTotal.people) + (reservationTotal.payDetails.cover * reservationTotal.people))
            | currency('$ ', 0, { thousandsSeparator: ',' }) }}
        </p>
        <p class="final-total-right" v-if="reservationTotal.typeReservation === 'Pombo'">
            {{ ((reservationTotal.payDetails.adult * reservationTotal.adult) + (reservationTotal.payDetails.boy * reservationTotal.boy))
            | currency('$ ', 0, { thousandsSeparator: ',' }) }}
        </p>
        <p class="final-total-right" v-if="reservationTotal.typeReservation === 'Brunch'">
            {{ (reservationTotal.payDetails.cover * reservationTotal.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }}
        </p>
    </div>
</template>
<script>
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';
export default {
  props: ['reservationTotal'],
  components: {
    TooltipTYC
  }
};
</script>
