<template>
  <div class="total-container" v-if="reservationInfo.isEvent">
    <p class="final-total-right">{{ $t("total") }}:</p>
    <p class="final-total-right">
      {{ reservationInfo.balance | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
  </div>
</template>
<script>
export default {
  props: ['reservationInfo']
};
</script>
