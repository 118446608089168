var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showGeneralContainer)?_c('div',[(
      !_vm.validateMareaSpecial() &&
      !_vm.reservationInfo.isEvent &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      _vm.reservationInfo.people >= _vm.params.minimumChargedPeople &&
      !_vm.getPayReservation() && !_vm.getPayReservationFixed()
    )?_c('div',{staticClass:"flex-container"},[_c('span',{staticClass:"item-left-total"},[_c('span',{staticClass:"checkout-item-subtitle"},[_vm._v(_vm._s(_vm.$t('prePurchase')))]),_c('p',{staticClass:"item-small-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.params.purchaseCost || 0),"$ ", 0, { thousandsSeparator: "," }))+"* x"+_vm._s(_vm.reservationInfo.people)+" pax ")])]),_c('p',{staticClass:"item-right-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.params.purchaseCost * _vm.reservationInfo.people),"$ ", 0, { thousandsSeparator: "," }))+" ")])]):_vm._e(),(
      !_vm.validateMareaSpecial() &&
      !_vm.reservationInfo.isEvent &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      _vm.getPayReservation() &&
      _vm.getPayReservationBoy() &&
      _vm.reservationInfo.boy > 0
    )?_c('div',{staticClass:"flex-container"},[_c('span',{staticClass:"item-left-total"},[_c('span',{staticClass:"checkout-item-subtitle"},[_vm._v(_vm._s(_vm.$t('prePurchase')))]),_c('p',{staticClass:"item-small-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationValue(),"$ ", 0, { thousandsSeparator: "," }))+"* x"+_vm._s(_vm.reservationInfo.adult)+" pax ")])]),_c('p',{staticClass:"item-right-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.adult),"$ ", 0, { thousandsSeparator: "," }))+" ")])]):_vm._e(),(
      !_vm.validateMareaSpecial() &&
      !_vm.reservationInfo.isEvent &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      _vm.getPayReservation() &&
      _vm.getPayReservationBoy() &&
      _vm.reservationInfo.boy > 0
    )?_c('div',{staticClass:"flex-container"},[_c('span',{staticClass:"item-left-total"},[_c('span',{staticClass:"checkout-item-subtitle"},[_vm._v(_vm._s(_vm.$t('prePurchase')))]),_c('p',{staticClass:"item-small-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationBoyValue(),"$ ", 0, { thousandsSeparator: "," }))+"* x"+_vm._s(_vm.reservationInfo.boy)+" pax ")])]),_c('p',{staticClass:"item-right-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationBoyValue() * _vm.reservationInfo.boy),"$ ", 0, { thousandsSeparator: "," }))+" ")])]):_vm._e(),(
      !_vm.validateMareaSpecial() &&
      !_vm.reservationInfo.isEvent &&
      _vm.reservationInfo.typeReservation !== 'Pombo' &&
      _vm.reservationInfo.typeReservation !== 'Rumba' &&
      _vm.reservationInfo.typeReservation !== 'Cena' &&
      _vm.reservationInfo.typeReservation !== 'Brunch' &&
      (_vm.getPayReservation() || _vm.getPayReservationFixed())
    )?_c('div',{staticClass:"flex-container"},[(_vm.getPayReservation())?_c('span',{staticClass:"item-left-total"},[_c('span',{staticClass:"checkout-item-subtitle"},[_vm._v(_vm._s(_vm.$t('prePurchase')))]),_c('p',{staticClass:"item-small-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationValue(),"$ ", 0, { thousandsSeparator: "," }))+"* x"+_vm._s(_vm.reservationInfo.people)+" pax ")])]):_vm._e(),(_vm.getPayReservation())?_c('p',{staticClass:"item-right-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e(),(_vm.getPayReservationFixed())?_c('span',{staticClass:"item-left-total"},[_c('span',{staticClass:"checkout-item-subtitle"},[_vm._v(_vm._s(_vm.$t('prePurchase')))]),_c('p',{staticClass:"item-small-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getPayReservationFixedValue(),"$ ", 0, { thousandsSeparator: "," }))+" ")])]):_vm._e(),(_vm.getPayReservationFixed())?_c('p',{staticClass:"item-right-total"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationFixedValue() * 1),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }