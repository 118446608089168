<template>
  <div>
    <div class="paymentCard-payu">
      <form @submit.prevent="validateBeforePse" class="medium-form pse-form form-payu-pse">
        <h3 class="title-type">{{$t('chooseBank')}}</h3>
        <section class="fields-container">
          <!-- Select Bank -->
          <span class="field-custom-validation-payu" style="width:100%; max-height: 30px;">
            <b-field class="bank-option custom-border"
              :message="errors.first('bank')"
              :type="{'is-danger': errors.has('bank')}"
            >
              <b-select
                :placeholder="$t('bank')"
                expanded
                id="bank"
                name="bank"
                v-model="pse.bank"
                v-validate="'required'"
              >
                <option
                  :key="'bank#' + b"
                  :value="bank.value"
                  v-for="(bank, b) in bank"
                >{{ bank.name }}</option>
              </b-select>
              <i class="icon-mks icon_arrow_select"></i>
            </b-field>
            <p class="help is-danger" v-if="validateBank">{{validateBank}}</p>
          </span>

          <!-- Person name -->
          <b-field class="person-name-option custom-border"
            :message="errors.first('name')"
            :type="{'is-danger': errors.has('name')}"
          >
            <b-input
              :placeholder="$t('name')"
              autofocus
              autocomplete="off"
              expanded
              icon-pack="fa"
              id="name"
              name="name"
              type="text"
              v-model="pse.name"
              v-validate="'required|alpha_spaces'"
            ></b-input>
          </b-field>

          <span class="field-custom-validation-payu aux-document-field">
            <b-field class="document-option" :class="{'padding-aux' : validateDoc}">
              <InputC
                :options="identificationTypes"
                :placeHolder="$t('documentNumber')"
                :valueInput="'' + pse.identification"
                @valueChange="changedValueIdentity"
                :start="identificationTypes[0].value"
                selector
              />
              <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Phone and country-->
          <span class="field-custom-validation-payu aux-phone-field">
              <b-field class="phone-option" :class="{'padding-aux-2' : validatePhone}">
                <inputSelector
                  @value="changedValuePhone"
                  :required="true"
                  :defaultFlags="true"
                  :defaultValidations="false"
                />
                <p class="help is-danger" v-if="validatePhone">{{validatePhone}}</p>
              </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validatePhone"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Email -->
          <b-field class="email-option custom-border"
            :message="errors.first('email')"
            :type="{'is-danger': errors.has('email')}"
          >
            <b-input
              :placeholder="$t('email')"
              autocomplete="off"
              expanded
              icon-pack="fa"
              id="email"
              name="email"
              type="text"
              v-model="pse.email"
              v-validate="'required|email'"
            ></b-input>
          </b-field>

          <footer class="payment-footer">
            <div class="person-type-option">
              <div>{{ $t('naturalPerson') }}</div>
              <b-switch
                name="individual"
                type="is-custom"
                v-model="individual"
                size="is-small"
                @input="validatePersonType('N')"
              ></b-switch>
              <div>{{ $t('legalPerson') }}</div>
              <b-switch
                name="association"
                type="is-custom"
                v-model="association"
                size="is-small"
                @input="validatePersonType('J')"
              ></b-switch>
            </div>
            <div class="secure-payment">
              {{$t('securePayments')}} &nbsp;
              <img src="img/payu-color.png" style="width: 2.5rem;" />
            </div>
          </footer>
        </section>
      </form>
    </div>
    <section class="confirmation-section">
      <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
        <div class="copy-terms">
          <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
          &nbsp;{{$t('termsReservationPay1')}}
          <a
            @click="showTerms2 = true"
            class="link-terms"
          >{{$t('termsAndCondiW')}}</a>
          {{$t('termsReservationPay2')}}
          <a
            @click="showTerms = true"
            class="link-terms"
          >{{$t('privacyPolicyW')}}*</a>
          .
          {{$t('termsReservationPay3')}}
        </div>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
            <terms :fecha="'22 de Noviembre del 2019'"></terms>
          </div>
        </b-modal>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms2">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
            <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
          </div>
        </b-modal>
      </div>
      <div class="btn-pay-container">
        <button
          @click="validateBeforePse"
          :class="{'opacity06': validarPSE}"
          :disabled="!authTerms"
          class="btn-pay"
          type="submit"
        >{{$t('pay')}}</button>
      </div>
    </section>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapGetters, mapActions } from 'vuex';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import _ from 'lodash';
import InputC from '@/components/customInput.vue';
import inputSelector from '@/components/Select/inputs/inputSelectCheckout.vue';

export default {
  components: { terms, terms2, InputC, inputSelector },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  data () {
    return {
      individual: true,
      association: false,
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      bank: [{ value: 0, label: 'Seleccione una entidad bancaria' }],
      typePerson: [
        { value: '', label: '' },
        { value: 'CC', label: 'CC - Cédula de ciudadanía' },
        { value: 'CE', label: 'CE - Cédula de extranjería' },
        { value: 'NIT', label: 'NIT - En caso de ser una empresa' },
        { value: 'TI', label: 'TI - Tarjeta de Identidad' },
        { value: 'PP', label: 'PP - Pasaporte' },
        {
          value: 'CEL',
          label: 'CEL - En caso de identificarse a través de la línea del móvil'
        },
        { value: 'RC', label: 'RC - Registro civil de nacimiento' },
        { value: 'DE', label: 'DE - Documento de identificación extranjero' }
      ],
      pse: {
        bank: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        typePeople: 'N',
        url: 'https://' + this.subDomain() + '.precompro.com/checkout'
      },
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      validateDoc: false,
      validatePhone: null,
      validateBank: null,
      enableValidations: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      banks: whiteLabelTypes.getters.banks,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip
    }),
    validarPSE () {
      return (
        this.pse.name === null || this.pse.name === '' ||
        this.pse.identification === null || this.pse.identification === '' ||
        this.pse.phone === null || this.pse.phone === '' || this.validatePhone !== null ||
        this.pse.email === null || this.pse.email === '' ||
        this.pse.bank === null || this.pse.bank === '' ||
        this.pse.typeDocument === null || this.pse.typeDocument === '' ||
        this.pse.typePeople === null || this.pse.typePeople === ''
      );
    }
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    validatePhoneRegex (phone, indicative) {
      switch (true) {
        case !phone:
          this.validatePhone = this.$t('checkoutValidations.phone.required');
          break;

        case !/^\d+$/.test(phone):
          this.validatePhone = this.$t('checkoutValidations.phone.numeric');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length < 7:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length > 11:
          this.validatePhone = this.$t('checkoutValidations.phone.max');
          break;

        case indicative === 57 && phone.length !== 10:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative === 507 && phone.length < 7 && phone.length > 8:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;
        // Ningún error
        default:
          this.validatePhone = null;
          break;
      }
    },
    changedValuePhone (values) {
      this.validatePhoneRegex(values.inputValue, values.countrieInfo.indicativo);
      this.pse.phone = values.inputValue;
    },
    changedValueIdentity (values) {
      if (this.enableValidations) {
        if (values.inputValue !== undefined && values.inputValue !== null && values.inputValue !== '') {
          this.validateDoc = null;
        } else {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        }
      }
      this.pse.identification = values.inputValue;
      this.pse.typeDocument = values.selectorValue;
    },
    validatePersonType (personType) {
      if (!this.association && !this.individual) {
        this.$nextTick(() => {
          this.individual = true;
          this.pse.typePeople = 'N';
        });
      }
      if (personType === 'N') {
        this.association = false;
        this.pse.typePeople = 'N';
      } else if (personType === 'J') {
        this.individual = false;
        this.pse.typePeople = 'J';
      }
    },
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    validateBeforePse () {
      this.$validator.validateAll().then(result => {
        this.enableValidations = true;
        if (this.pse.bank === null || this.pse.bank === '' || this.pse.bank === undefined) {
          this.validateBank = this.$t('checkoutValidations.bank.required');
        } else {
          this.validateBank = null;
        }
        if (this.pse.identification === null || this.pse.identification === '') {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        } else {
          this.validateDoc = null;
        }
        if (this.pse.phone === null || this.pse.phone === '' || this.pse.phone === undefined) {
          this.validatePhone = this.$t('checkoutValidations.phone.required');
        }
        if (this.validateDoc === null && this.validatePhone === null && this.validateBank === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            this.proccessPse(this.card);
          }
        }
      });
    },
    proccessPse () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.paymentProvider = 'payu';
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.confirmReservation({
          reservation: this.reservation,
          pse: this.pse,
          paymentProvider: 'payu'
        })
          .then(({ data }) => {
            if (data.code === 799) {
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
              return;
            }
            if (data.transactionResponse.state === 'PENDING') {
              window.location.href = data.transactionResponse.extraParameters.BANK_URL;
            } else {
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>Operación fallida <br><strong>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            }
          })
          .catch(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
          });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    selectBank (selected) {
      this.pse.bank = selected[0].value;
    },
    typePersonSelect (selected) {
      this.pse.typeDocument = selected[0].value;
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identificationTypes = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    }
  },
  mounted () {
    this.$emit('validateCheck');
    this.getIdentification();
    this.pse.url = window.location.href;
    let aux = [];
    _.forEach(this.banks, function (data) {
      aux.push({ name: data.description, value: data.pseCode });
    });
    this.bank = aux;
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
  },
  watch: {
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    },
    'pse.bank': {
      handler () {
        if (this.pse.bank !== undefined && this.pse.bank !== null && this.pse.bank !== '') {
          this.validateBank = null;
        }
      }
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardPayU.scss";
</style>
