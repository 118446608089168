<template>
  <div class="bg-steper" :class="{noBackgroundStepper: embedSystem}">
    <div class="container steper-container">
      <div class="item-stepper nextPartMobile" :class="{beforeParteMobile:paso === 2}">
        <span v-if="paso === 1">2. {{ $t('confirm') }}</span>
        <span v-else>1.{{ $t(`selectDataReservation`) }}</span>
        <section class="composeIcon">
          <iconReservation
            class="margin-half icon-steper"
            size="2rem"
          ></iconReservation>
            <div v-show="paso === 1"><i></i></div>
        </section>
      </div>
      <div class="item-stepper">
        <span v-if="selectedSection === 1">1.1 {{ $t(`selectedNumberPerson`) }}</span>
        <span v-if="selectedSection === 2">1.2 {{ $t(`selectDate`) }}</span>
        <span v-if="selectedSection === 3">1.3 {{ $t(`selectHour`) }}</span>
      </div>
      <div id="item-stepper" v-if="paso === 2">
        <span v-if="haveToPay">2. {{ (isShowRequestReservation) ? $t('requestReservationConfirm') : $t('reservationConfirm') }}</span>
        <span v-else>2. {{ $t('payReservation') }}</span>
      </div>
      <div class="item-full-info">
        <!-- ============== Primer paso personas ==============-->
        <span :class="{'opacity03': paso !== 1}" class="name-stepper">1. {{ $t('selectData') }}</span>
        <div class="stepperDivisor"></div>
        <!-- ============== Segundo paso fechas ==============-->
        <span
          :class="{'opacity03': paso !== 2}"
          class="name-stepper"
          v-if="haveToPay"
        >2. {{ (isShowRequestReservation) ? $t('bookRequest') : $t('book') }}</span>
        <span
          :class="{'opacity03': paso !== 2}"
          class="name-stepper"
          v-else
        > 2. {{ $t('stepper.pay') }}</span>
      </div>
      <div class="itemStepperDown">
        <iconReservation
          class="margin-half icon-steper"
          size="2rem"
        ></iconReservation>
        <p v-if="peopleSelect">{{peopleSelect}} pax</p>
        <span v-show="selectedDate || selectedDateTemporal">/</span>
        <p v-if="selectedDate || selectedDateTemporal"> {{(selectedDate || selectedDateTemporal) | moment('timezone', vendorTimezone, 'MMMM  DD ')}}</p>
        <span v-show="(selectedDate || selectedDateTemporal) && selectedHour">/</span>
        <p v-if="(selectedDate || selectedDateTemporal) && selectedHour" >{{(selectedHour ? selectedHour  : selectedDate) | moment('timezone', vendorTimezone, 'h:mm a')}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import iconReservation from '@/components/Icons/iconReservation';

export default {
  props: ['paso'],
  components: {
    iconReservation
  },
  computed: {
    ...mapGetters({
      getSelectedDate: [reservationTypes.getters.selectedDate],
      getSelectedHour: [reservationTypes.getters.selectedHour],
      selectedPeople: [reservationTypes.getters.selectedPeople],
      selectedSection: [whiteLabelTypes.getters.section],
      reservation: reservationTypes.getters.reservationsUser,
      selectedDateTemporal: reservationTypes.getters.selectedDateTemporal
    }),
    peopleSelect () {
      if (this.selectedPeople) {
        return this.selectedPeople;
      }
      return 0;
    },
    haveToPay () {
      if (this.reservation) {
        return this.reservation.isFree;
      } else {
        return this.isFree;
      }
    },
    selectedDate () {
      if (this.getSelectedDate) {
        return this.getSelectedDate;
      }
      return 0;
    },
    selectedHour () {
      if (this.getSelectedHour) {
        return this.getSelectedHour;
      }
      return 0;
    }
  },
  watch: {
    selectedPeople (value) {
      this.messageAnticipe(value, this.params);
    }
  }
};
</script>
<style lang="scss" scoped>
.bg-steper {
  min-height: 3.5rem;
  background: var(--bgColorStepper);
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
  .item-full-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
#item-stepper {
  color: var(--colorSteper);
}
.icon-steper {
  fill: var(--colorSteper);
}
.name-stepper {
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  color: var(--colorSteper);
  @media screen and (max-width:600px) {
    display: none;
  }
}
.steper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 430px !important;
  min-width: 380px !important;
  @media screen and (max-width:600px) {
    flex-direction: column;
    span{
      color: var(--colorSteper);
      text-align: center;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

  }
}
.opacity03 {
  opacity: 0.3;
}
.margin-steper {
  margin: 0px 1rem;
}
@media (min-width: 959px) {
  .lineShort {
    display: none;
  }
}
@media (min-width: 601px){
  #item-stepper{
    display: none;
  }
}
.stepperDivisor{
  width: 70px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--colorSteper);
  transform: translateY(2px);
  margin: 0px 20px;
  @media screen and (max-width:600px) {
    display: none;
  }
}
.itemStepperDown{
  display: flex;
  align-items: center;
  gap:  4px;
  padding-top: 5px;
  p,span{
    color: var(--colorSteper);
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.item-stepper{
  &.nextPartMobile{
    &>span{
    transform: scale(0.75);
    }
    display: flex;
    width: 100px;
    height: 40px;
    width: fit-content;
    position: absolute;
    right: -18px;
    top: 4px;
    margin: auto;
    flex-direction: column;
    opacity: 0.3;
    align-items: center;
    @media screen and (min-width:600px) {
      display: none;
    }
    &.beforeParteMobile{
      right:  unset;
      left: -15px;
      @media screen and (max-width: 400px) {
        left: 0;
      }
    }
  }
  .composeIcon{
    width: 35px;
    height: 15px;
    position: relative;
    margin: unset;
    div:nth-child(1){
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      mask-repeat: no-repeat;
      mask-size: contain;
      background-color: var(--colorSteper);
      mask-image: url(../../assets/images/icons/reservation/icon_reservations.svg);
    }
    div:nth-child(2){
      z-index: 1000000;
      position: absolute;
      bottom: -5px;
      right: -3px;
      display: block;
      height: 65%;
      width: 35%;
      border-radius: 50%;
      background-color: var(--bgColorStepper);;

      i{
      mask-repeat: no-repeat;
      mask-size: contain;
      background-color: var(--colorSteper);
      mask-image: url(../../assets/images/icons/reservation/icon_check.svg);
      width: 100%;
      height: 100%;
      display: block;
      }
    }
  }
  @media screen and (min-width:600px) {
    display: none;
  }
}
.noBackgroundStepper {
  background: transparent;
}
</style>
