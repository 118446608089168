<template>
  <div>
    <div class="paymentCard-pse-mp" >
      <form @submit.prevent="validateBeforePse" class="medium-form pse-form form-mp-pse">
        <h3 class="title-type">{{$t('chooseBank')}}</h3>
        <section class="fields-container">
          <!-- Select Bank -->
          <span class="field-custom-validation-payu" style="width:100%; max-height: 30px;">
            <b-field class="bank-option custom-border"
              :message="errors.first('bank')"
              :type="{'is-danger': errors.has('bank')}"
            >
              <b-select
                :placeholder="$t('bank')"
                expanded
                id="bank"
                name="bank"
                v-model="pse.transaction_details.financial_institution"
                v-validate="'required'"
              >
                <option
                  :key="'bank#' + b"
                  :value="bank.id"
                  v-for="(bank, b) in banks"
                >{{ bank.description }}</option>
              </b-select>
              <i class="icon-mks icon_arrow_select less"></i>
            </b-field>
            <p class="help is-danger" v-if="validateBank">{{validateBank}}</p>
          </span>

          <!-- Person name -->
          <b-field class="person-name-option custom-border"
            :message="errors.first('name')"
            :type="{'is-danger': errors.has('name')}"
          >
            <b-input
              :placeholder="$t('name')"
              autocomplete="off"
              autofocus
              icon
              icon-pack="fa"
              id="name"
              name="name"
              type="text"
              v-model="pse.payer.name"
              v-validate="'required|alpha_spaces|min:2'"
            ></b-input>
          </b-field>

          <!-- Identity document -->
          <span class="field-custom-validation-mp aux-document-field">
            <b-field class="document-option" :class="{'padding-aux' : validateDoc}">
              <InputC
                :options="identificationTypes"
                :placeHolder="$t('documentNumber')"
                :valueInput="'' + pse.payer.identification.number"
                @valueChange="changedValueIdentity"
                @blurInput="validateIdentityType"
                :start="identificationTypes[0].value"
                selector
              />
              <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Phone and country -->
          <span class="field-custom-validation-mp aux-phone-field">
            <b-field class="phone-option" :class="{'padding-aux-2' : validatePhone}">
              <inputSelector
                @value="changedValuePhone"
                :required="true"
                :defaultFlags="true"
                :defaultValidations="false"
              />
              <p class="help is-danger" v-if="validatePhone">{{validatePhone}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validatePhone"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Email -->
          <b-field class="email-option custom-border"
            :message="errors.first('email')"
            :type="{'is-danger': errors.has('email')}"
          >
            <b-input
              :placeholder="$t('email')"
              expanded
              icon-pack="fa"
              autocomplete="off"
              id="email"
              name="email"
              type="text"
              v-model="pse.payer.email"
              v-validate="'required|email'"
            ></b-input>
          </b-field>

          <footer class="payment-footer">
            <div class="person-type-option">
              <span>{{ $t('naturalPerson') }}</span>
              <b-switch
                name="individual"
                type="is-custom"
                v-model="individual"
                size="is-small"
                @input="validatePersonType('individual')"
              ></b-switch>
              <span>{{ $t('legalPerson') }}</span>
              <b-switch
                name="association"
                type="is-custom"
                v-model="association"
                size="is-small"
                @input="validatePersonType('association')"
              ></b-switch>
            </div>
            <div class="secure-payment">
              {{$t('securePayments')}} &nbsp;
              <img src="img/mercadoPago.svg" />
            </div>
          </footer>
        </section>
      </form>
    </div>
    <section class="confirmation-section">
      <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
        <div class="copy-terms">
          <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
          &nbsp;{{$t('termsReservationPay1')}}
          <a
            @click="showTerms2 = true"
            class="link-terms"
          >{{$t('termsAndCondiW')}}</a>
          {{$t('termsReservationPay2')}}
          <a
            @click="showTerms = true"
            class="link-terms"
          >{{$t('privacyPolicyW')}}*</a>
          {{$t('termsReservationPay2Aux')}}.
        </div>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
            <terms :fecha="'22 de Noviembre del 2019'"></terms>
          </div>
        </b-modal>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms2">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
            <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
          </div>
        </b-modal>
      </div>
      <div class="btn-pay-container">
        <button
          :class="{'opacity06': validatePayment}"
          :disabled="!authTerms"
          class="btn-pay"
          type="submit"
          @click="validateBeforePse"
        >{{$t('pay')}}</button>
      </div>
    </section>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapGetters, mapActions } from 'vuex';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import _ from 'lodash';
import InputC from '@/components/customInput.vue';
import inputSelector from '@/components/Select/inputs/inputSelectCheckout.vue';

export default {
  components: { terms, terms2, InputC, inputSelector },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  data () {
    return {
      individual: true,
      association: false,
      banks: [],
      typePerson: [],
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        payment_method_id: 'pse',
        payer: {
          name: null,
          phone: null,
          email: null,
          entity_type: 'individual',
          identification: {
            type: 'CC',
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null
      },
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      validateBank: false,
      validateDoc: false,
      validatePhone: false,
      enableValidations: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip
    }),
    validatePayment () {
      const pse = this.pse;
      return (
        !this.authTerms ||
        pse.payer.identification.type === null || pse.payer.identification.type === '' ||
        pse.payer.identification.number === null || pse.payer.identification.number === '' ||
        pse.payer.email === null || pse.payer.email === '' ||
        pse.payer.entity_type === null || pse.payer.entity_type === '' ||
        pse.transaction_details.financial_institution === null || pse.transaction_details.financial_institution === '' || this.validatePhone !== null ||
        this.errors.items.length > 0
      );
    }
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    validateIdentityType () {
      if (this.pse.payer.identification.type === ' ') {
        this.pse.payer.identification.type = 'CC';
      }
    },
    validatePhoneRegex (phone, indicative) {
      switch (true) {
        case !phone:
          this.validatePhone = this.$t('checkoutValidations.phone.required');
          break;

        case !/^\d+$/.test(phone):
          this.validatePhone = this.$t('checkoutValidations.phone.numeric');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length < 7:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length > 11:
          this.validatePhone = this.$t('checkoutValidations.phone.max');
          break;

        case indicative === 57 && phone.length !== 10:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative === 507 && phone.length < 7 && phone.length > 8:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;
        // Ningún error
        default:
          this.validatePhone = null;
          break;
      }
    },
    changedValuePhone (values) {
      this.validatePhoneRegex(values.inputValue, values.countrieInfo.indicativo);
      this.pse.payer.phone = values.inputValue;
    },
    changedValueIdentity (values) {
      if (this.enableValidations) {
        if (values.inputValue !== undefined && values.inputValue !== null && values.inputValue !== '') {
          this.validateDoc = null;
        } else {
          this.validateDoc = this.$t('checkoutValidations.phone.required');
        }
      }
      this.pse.payer.identification.number = values.inputValue;
      this.pse.payer.identification.type = values.selectorValue;
    },
    validatePersonType (personType) {
      if (!this.association && !this.individual) {
        this.$nextTick(() => {
          this.individual = true;
          this.pse.payer.entity_type = 'individual';
        });
      }
      if (personType === 'individual') {
        this.association = false;
        this.pse.payer.entity_type = 'individual';
      } else if (personType === 'association') {
        this.individual = false;
        this.pse.payer.entity_type = 'association';
      }
    },
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    customValidationsBeforeSubmit () {
      if (this.pse.transaction_details.financial_institution === null || this.pse.transaction_details.financial_institution === '' || this.pse.transaction_details.financial_institution === undefined) {
        this.validateBank = this.$t('checkoutValidations.bank.required');
      } else {
        this.validateBank = null;
      }
      if (this.pse.payer.identification.number === null || this.pse.payer.identification.number === '') {
        this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
      } else {
        this.validateDoc = null;
      }
      if (this.pse.payer.phone === null || this.pse.payer.phone === '' || this.pse.payer.phone === undefined) {
        this.validatePhone = this.$t('checkoutValidations.phone.required');
      }
    },
    validateBeforePse () {
      this.$validator.validateAll().then(result => {
        this.enableValidations = true;
        this.customValidationsBeforeSubmit();
        if (this.validateDoc === null && this.validatePhone === null && this.validateBank === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            this.proccessPse(this.card);
          }
        }
      });
    },
    proccessPse () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.paymentProvider = 'mp';
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        delete this.pse.payer.name;
        delete this.pse.payer.phone;
        this.confirmReservation({
          reservation: this.reservation,
          pse: this.pse,
          paymentProvider: 'mp'
        }).then(({ data }) => {
          if (data.code === 799) {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.code === 200) {
            window.location.href = data.url;
          } else {
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>Operación fallida <br><strong>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          }
        }).catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
        });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    getPaymentOptions () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let info = _.find(data.data, { id: 'pse' });
          this.banks = info.financial_institutions;
        } else {
          this.banks = [];
        }
      });
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identificationTypes = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    }
  },
  mounted () {
    this.getPaymentOptions();
    this.getIdentification();
    this.$emit('validateCheck');
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
    this.pse.transaction_amount = this.reservation.balance;
    this.pse.net_amount = this.reservation.balance;
    let trans = this.$route.query;
    if (Object.keys(trans).length > 0) {
      if (trans.source_utm) {
        this.pse.callback_url = (process.env.NODE_ENV === 'production') ? window.location.href : 'https://dev.precompro.co/checkout';
      } else {
        this.pse.callback_url = (process.env.NODE_ENV === 'production') ? window.location.href + '?source_utm=mp' : 'https://dev.precompro.co/checkout?source_utm=mp';
      }
    } else {
      this.pse.callback_url = (process.env.NODE_ENV === 'production') ? window.location.href + '?source_utm=mp' : 'https://dev.precompro.co/checkout?source_utm=mp';
    }
  },
  watch: {
    reservation () {
      this.pse.transaction_amount = this.reservation.balance;
      this.pse.net_amount = this.reservation.balance;
    },
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    },
    'pse.transaction_details.financial_institution': {
      handler () {
        if (this.pse.transaction_details.financial_institution !== null && this.pse.transaction_details.financial_institution !== '') {
          this.validateBank = null;
        }
      }
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardMP.scss";
</style>
