<template>
  <div>
    <div class="paymentCard-payu" >
      <form @submit.prevent="validateBeforeCard" class="form-payu-credit">
        <h3 class="title-type">{{$t('payerDetails')}}</h3>
        <!-- Person Data -->
        <section class="fields-container person-data-fields">

          <!-- Person name -->
          <b-field class="person-name-option custom-border"
            :message="errors.first('name')"
            :type="{'is-danger': errors.has('name')}"
          >
            <b-input
              :placeholder="$t('cardholder')"
              autocomplete="off"
              autofocus
              icon
              icon-pack="fa"
              id="name"
              name="name"
              type="text"
              v-model="card.name"
              v-validate="'required|alpha_spaces|min:2'"
            ></b-input>
          </b-field>

          <!-- Identity document -->
          <span class="field-custom-validation-payu aux-document-field">
            <b-field class="document-option" :class="{'padding-aux' : validateDoc}">
              <InputC
                :options="identificationTypes"
                :placeHolder="$t('documentNumber')"
                :valueInput="'' + card.identification"
                @valueChange="changedValueIdentity"
                selector
                :required="true"
                :start="identificationTypes[0].value"
                :typeProvider="'payU'"
              />
              <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
          </span>

          <!-- Phone and country -->
          <span class="field-custom-validation-payu aux-phone-field">
            <b-field class="phone-option" :class="{'padding-aux-2' : validatePhone}"
            >
              <inputSelector
                @value="changedValuePhone"
                :required="true"
                :defaultFlags="true"
                :defaultValidations="false"
              />
              <p class="help is-danger" v-if="validatePhone">{{validatePhone}}</p>
            </b-field>
            <span class="icon is-right has-text-danger custom-validation-danger" v-if="validatePhone"><i class="fa fa-exclamation-circle"></i></span>
          </span>
          <!-- Address -->
          <b-field class="address-option custom-border"
            :message="errors.first('address_checkout')"
            :type="{'is-danger': errors.has('address_checkout')}"
          >
            <b-input
              :placeholder="$t('address')"
              autocomplete="off"
              icon
              icon-pack="fa"
              id="address"
              name="address_checkout"
              type="text"
              v-model="card.address"
              v-validate="'required|min:6'"
            ></b-input>
          </b-field>

          <!-- City -->
          <b-field class="city-option custom-border"
            :message="errors.first('city')"
            :type="{'is-danger': errors.has('city')}"
          >
            <b-input
              :placeholder="$t('city')"
              autocomplete="off"
              icon
              icon-pack="fa"
              id="city"
              name="city"
              type="text"
              v-model="card.city"
              v-validate="'required|min:3'"
            ></b-input>
          </b-field>

          <!-- Email -->
          <b-field class="email-option custom-border"
            :message="errors.first('email')"
            :type="{'is-danger': errors.has('email')}"
          >
            <b-input
              :placeholder="$t('prepagoView.text22')"
              autocomplete="off"
              icon
              icon-pack="fa"
              id="email"
              name="email"
              type="text"
              v-model="card.email"
              v-validate="'required|email'"
            ></b-input>
          </b-field>
        </section>

        <h3 class="title-type">{{$t('cardData')}}</h3>
        <!-- Card Data -->
        <section class="fields-container card-data-fields">

          <!-- Card Number -->
          <b-field class="card-number-option custom-border"
            :message="errors.first('card')"
            :type="{'is-danger': errors.has('card')}"
          >
            <b-input
              :maxlength="maxNumbers"
              autofocus
              autocomplete="off"
              expanded
              icon
              icon-pack="fa"
              id="card"
              name="card"
              :placeholder="$t('prepagoView.text25')"
              type="text"
              v-mask="['#### #### #### #####']"
              v-model="card.card"
              v-validate="'required|min:14|max:21'"
            ></b-input>
          </b-field>

          <!-- Month -->
          <b-field class="month-option custom-border"
            :message="errors.first('mes')"
            :type="{'is-danger': errors.has('mes')}"
          >
            <Dropdown
              :cbChanged="changedSelectMes"
              :data="mes"
              :placeholder="$t('month')"
              name="mes"
              v-model="card.mes"
              v-validate="'required'"
            ></Dropdown>
          </b-field>

          <!-- Year -->
          <b-field class="year-option custom-border"
            :message="errors.first('ano')"
            :type="{'is-danger': errors.has('ano')}"
          >
            <Dropdown
              :cbChanged="changedSelectAno"
              :data="ano"
              :placeholder="$t('year')"
              name="ano"
              v-model="card.ano"
              v-validate="'required'"
            ></Dropdown>
          </b-field>

        </section>

        <footer class="payment-footer">
          <!-- Save Card -->
          <div class="future-box" v-if="false">
            <div>
              {{$t('saveCard')}}
            </div>
            <b-switch
              :value="true"
              checked="true"
              id="saveCard"
              name="saveCard"
              type="is-custom"
              v-model="card.checked"
            ></b-switch>
          </div>
          <!-- Secure Payment -->
          <div class="secure-payment content-aux-right">
            <div>{{$t('securePayments')}}</div>
            <img src="img/payu-color.png"/>
          </div>
        </footer>
      </form>
    </div>
    <section class="confirmation-section">
      <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
        <div class="copy-terms">
          <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
          &nbsp;{{$t('termsReservationPay1')}}
          <a
            @click="showTerms2 = true"
            class="link-terms"
          >{{$t('termsAndCondiW')}}</a>
          {{$t('termsReservationPay2')}}
          <a
            @click="showTerms = true"
            class="link-terms"
          >{{$t('privacyPolicyW')}}*</a>
          {{$t('termsReservationPay2Aux')}}.
        </div>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
            <terms :fecha="'22 de Noviembre del 2019'"></terms>
          </div>
        </b-modal>
      </div>
      <div id="modal-terms">
        <b-modal :active.sync="showTerms2">
          <div class="terms-body">
            <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
            <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
          </div>
        </b-modal>
      </div>
      <div class="btn-pay-container">
        <button @click="validateBeforeCard"
          :class="{'opacity06': validatePayment}"
          :disabled="(loader || !authTerms)"
          class="btn-pay"
          type="submit"
        >{{ card.checked ? $t('addCardPay') : $t('pay') }}</button>
      </div>
    </section>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import { mapGetters, mapActions } from 'vuex';
import InputC from '@/components/customInput.vue';
import inputSelector from '@/components/Select/inputs/inputSelectCheckout.vue';

export default {
  components: { terms, terms2, InputC, inputSelector },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  data () {
    return {
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      maxNumbers: 19,
      cardType: '',
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        email: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: 1,
        checked: true
      },
      mes: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      ano: [],
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      validateDoc: null,
      validatePhone: null,
      enableValidations: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      loader: whiteLabelTypes.getters.loader,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip,
      systemDate: globalTypes.getters.serverTime
    }),
    validatePayment () {
      const card = this.card;
      return (
        !this.authTerms ||
        card.name === null || card.name === '' ||
        card.identification === null || card.identification === '' ||
        card.phone === null || card.phone === '' || card.phone === undefined || this.validatePhone !== null ||
        card.address === null || card.address === '' ||
        card.city === null || card.city === '' ||
        card.card === null || card.card === '' ||
        card.mes === null || card.mes === '' ||
        card.ano === null || card.ano === '' ||
        this.errors.items.length > 0
      );
    }
  },
  mounted () {
    this.getIdentification();
    this.$emit('validateCheck');
    const { data } = this.getYears(this.systemDate);
    this.ano = data;
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
  },
  watch: {
    card: {
      handler () {
        if (this.card.card != null) {
          const cardJustNumber = this.card.card.split(/\s+/).join('');
          const regexDiner = new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{5,}$/);
          const regexVisa = new RegExp(/^4[0-9]{6,}$/);
          const regexAmex = new RegExp(/^3[47][0-9]{5,}$/);
          const regexMasterCard = new RegExp(/^5[1-5][0-9]{14}$/);

          if (regexVisa.test(cardJustNumber)) {
            this.cardType = 'Visa';
          } else if (regexAmex.test(cardJustNumber)) {
            this.cardType = 'Amex';
          } else if (regexMasterCard.test(cardJustNumber)) {
            this.cardType = 'MasterCard';
          } else if (regexDiner.test(cardJustNumber)) {
            this.maxNumbers = 21;
            this.cardType = 'Diners';
          } else {
            this.cardType = '';
            this.maxNumbers = 19;
          }
        }
      },
      deep: true
    },
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    },
    '$i18n.locale' (newLocale, oldLocale) {
      this.getIdentification();
    }
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    changedValueIdentity (values) {
      if (this.enableValidations) {
        if (values.inputValue !== undefined && values.inputValue !== null && values.inputValue !== '') {
          this.validateDoc = null;
        } else {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        }
      }
      this.card.identification = values.inputValue;
    },
    validatePhoneRegex (phone, indicative) {
      switch (true) {
        case !phone:
          this.validatePhone = this.$t('checkoutValidations.phone.required');
          break;

        case !/^\d+$/.test(phone):
          this.validatePhone = this.$t('checkoutValidations.phone.numeric');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length < 7:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length > 11:
          this.validatePhone = this.$t('checkoutValidations.phone.max');
          break;

        case indicative === 57 && phone.length !== 10:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative === 507 && phone.length < 7 && phone.length > 8:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;
        // Ningún error
        default:
          this.validatePhone = null;
          break;
      }
    },
    changedValuePhone (values) {
      this.validatePhoneRegex(values.inputValue, values.countrieInfo.indicativo);
      this.card.phone = values.inputValue;
    },
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    validateBeforeCard () {
      this.$validator.validateAll().then(result => {
        this.enableValidations = true;
        if (this.card.identification === null || this.card.identification === '') {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        } else {
          this.validateDoc = null;
        }
        if (this.card.phone === null || this.card.phone === '' || this.card.phone === undefined) {
          this.validatePhone = this.$t('checkoutValidations.phone.required');
        }
        if (this.validateDoc === null && this.validatePhone === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            this.card.expiration = this.card.ano + '/' + this.card.mes;
            this.proccessCard();
          }
        }
      });
    },
    proccessCard () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.paymentProvider = 'payu';
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.confirmReservation({
          reservation: this.reservation,
          cardCredit: this.card,
          paymentProvider: 'payu'
        }).then(({ data }) => {
          if (data.code === 799) {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>" + data.message,
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
            return;
          }
          if (data.code === 705) {
            const msg = data.info;
            this.$emit('cancelTransactionCard', msg);
          }
          if (data.code === 701 || data.code === 702 || data.code === 703 || data.code === 704) {
            this.$emit('cancelTransactionCard', data.message);
          }
          if (data.code === 710 || data.code === 711) {
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error <br><strong>Intenta realizar la reserva de nuevo</strong> ",
              type: 'is-warning',
              position: 'is-bottom',
              actionText: 'X',
              queue: false
            });
          }
          if (data.code === 780) {
            this.$emit('pendingTransactionCard', true);
          }
          if (data.code === 200) {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.reservation.infoId = data.id;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
            this.$store.commit(reservationTypes.mutations.setReservationsUser);
            window.localStorage.setItem('_reservationOk', true);
            this.$store.commit(
              whiteLabelTypes.mutations.setShowModalReservationConfirm
            );
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            window.location.href = '/confirmation';
          }
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        }).catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$buefy.snackbar.open({
            duration: 5000,
            message:
              "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
        });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    changedSelectMes (selected) {
      this.card.mes = selected[0].value;
    },
    changedSelectAno (selected) {
      this.card.ano = selected[0].value;
    },
    getIdentification () {
      const options = [
        {
          'id': 'CC',
          'name': 'C.C.',
          'en': 'ID',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'CE',
          'name': 'C.E.',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'NIT',
          'name': 'NIT',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        },
        {
          'id': 'Otro',
          'name': 'Otro',
          'type': 'number',
          'min_length': 5,
          'max_length': 20
        }
      ];
      this.identificationTypes = options.map((i) => {
        return {
          name: (this.$i18n.locale === 'en' && i.en) ? i.en : i.name,
          value: i.id
        };
      });
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardPayU.scss";
</style>
