import { render, staticRenderFns } from "./PseWompi.vue?vue&type=template&id=17a6fff1"
import script from "./PseWompi.vue?vue&type=script&lang=js"
export * from "./PseWompi.vue?vue&type=script&lang=js"
import style0 from "./PseWompi.vue?vue&type=style&index=0&id=17a6fff1&prod&lang=scss"
import style1 from "./PseWompi.vue?vue&type=style&index=1&id=17a6fff1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports