<template>
  <div>
    <div
      class="total-container"
      v-if="
        !validateMareaSpecial() &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        !reservationInfo.decoration &&
        experiencePay <= 0 &&
        !params.activeExperiencies &&
        !reservationInfo.isEvent
      "
    >
      <TooltipTYC @showTerms="$emit('showTerms')"/>
      <p class="final-total-right">{{ $t("total") }}:</p>
      <p class="final-total-right" v-if="!getPayReservation() && !getPayReservationBoy() && !getPayReservationFixed()">
        {{ (params.purchaseCost * reservationInfo.people + getExperiencesValue()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <p class="final-total-right" v-if="getPayReservation() && !getPayReservationBoy() && !getPayReservationFixed()">
        {{ (getPayReservationValue() * reservationInfo.people + getExperiencesValue() + getTip3Cord()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <p class="final-total-right" v-if="!getPayReservation() && !getPayReservationBoy() && getPayReservationFixed()">
        {{ getPayReservationFixedValue() | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>

      <p class="final-total-right" v-if="getPayReservation() && getPayReservationBoy() && reservationInfo.boy > 0 && !getPayReservationFixed()">
        {{ (getPayReservationValue() * reservationInfo.adult + getPayReservationBoyValue() * reservationInfo.boy + getExperiencesValue() + getTip3Cord())
        | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <!-- 3 CORDILLERAS ENTRA AQUÍ -->
      <p class="final-total-right" v-if="getPayReservation() && getPayReservationBoy() && reservationInfo.boy === 0">
        {{ (getPayReservationValue() * reservationInfo.people + getExperiencesValue() + getTip3Cord()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
    </div>
    <div
      class="total-container"
      v-if="
        !validateMareaSpecial() &&
        reservationInfo.typeReservation !== 'Rumba' &&
        reservationInfo.typeReservation !== 'Cena' &&
        reservationInfo.typeReservation !== 'Pombo' &&
        reservationInfo.typeReservation !== 'Brunch' &&
        !reservationInfo.decoration &&
        experiencePay <= 0 &&
        params.activeExperiencies &&
        !reservationInfo.isEvent
      "
    >
      <TooltipTYC @showTerms="$emit('showTerms')"/>
      <p class="final-total-right">{{ $t("total") }}:</p>
      <p class="final-total-right" v-if="!getPayReservation() && !getPayReservationBoy() && !getPayReservationFixed()">
        {{ (params.purchaseCost * reservationInfo.people + getExperiencesValue()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <p class="final-total-right" v-if="getPayReservation() && !getPayReservationBoy() && !getPayReservationFixed()">
        {{ (getPayReservationValue() * reservationInfo.people + getExperiencesValue() + getTip3Cord()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <p class="final-total-right" v-if="!getPayReservation() && !getPayReservationBoy() && getPayReservationFixed()">
        {{ getPayReservationFixedValue() | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <p class="final-total-right" v-if="getPayReservation() && getPayReservationBoy() && reservationInfo.boy > 0 && !getPayReservationFixed()">
        {{ (getPayReservationValue() * reservationInfo.adult + getPayReservationBoyValue() * reservationInfo.boy + getExperiencesValue() + getTip3Cord())
        | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
      <!-- 3 CORDILLERAS ENTRA AQUÍ -->
      <p class="final-total-right" v-if="getPayReservation() && getPayReservationBoy() && reservationInfo.boy === 0">
        {{ (getPayReservationValue() * reservationInfo.people + getExperiencesValue() + getTip3Cord()) | currency("$ ", 0, { thousandsSeparator: "," }) }}
      </p>
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  props: ['reservationInfo', 'experiencePay', 'payAdultMarea', 'payBoyMarea', 'experienceCostT'],
  components: {
    TooltipTYC
  },
  computed: {
    ...mapGetters({
      tooltipTYC: whiteLabelTypes.getters.tooltipTYC
    })
  },
  methods: {
    getExperiencesValue () {
      return this.experiencePay;
    },
    getTip3Cord () {
      let tip = 0;
      let total = (this.getPayReservationValue() * this.reservationInfo.people) + this.getExperiencesValue();
      // eslint-disable-next-line
      if (this.tip3Cord != null && this.tip3Cord == '10') {
        tip = (total * 0.10);
      }
      // eslint-disable-next-line
      if (this.tip3Cord != null && this.tip3Cord == 'other') {
        tip = this.tipCustom;
      }
      this.$store.commit(reservationTypes.mutations.setTip, tip);
      return tip;
    },
    validateMareaSpecial () {
      if (
        this.vendor.id === 231 &&
        (this.payAdultMarea > 0 || this.payBoyMarea > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getPayReservation () {
      let data = false;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = parseInt(info);
      }
      return data;
    },
    getPayReservationFixed () {
      let data = false;
      let info = window.localStorage.getItem('payReservationsFixed');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationFixedValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservationsFixed');
      if (info) {
        data = parseInt(info);
      }
      return data;
    },
    getPayReservationBoy () {
      let data = false;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationBoyValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info) {
        data = parseInt(info);
      }
      return data;
    }
  }
};
</script>
