<template>
  <footer
    :style="{ 'background': rgbaBackground(design.footerBackgroundColor), 'opacity' : 1 }"
    class="footer"
    :class="{noPaddingAux : !isFreeFooter, ajustNoShow: embedSystem}"
    v-if="vendor && design && params"
  >
    <div class="container overflow-h">
      <div
        :class="{'social' : vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp}"
        class="build-bottom columns"
        v-show="!embedSystem"
      >
        <div
          id="socialRedesFooter"
          v-if="(vendor.facebook || vendor.instagram || vendor.twitter || vendor.whatsapp)"
        >
          <i @click="openSocial('facebook')" class="icon_facebook" v-if="vendor.facebook"></i>
          <i @click="openSocial('twitter')" class="icon_twitter" v-if="vendor.twitter"></i>
          <i @click="openSocial('instagram')" class="icon_instagram" v-if="vendor.instagram"></i>
          <i @click="openSocial('whatsapp')" class="icon_whatsapp" v-if="vendor.whatsapp"></i>
        </div>
        <div class="column" v-show="!embedSystem">
          <p class="info-footer">
            <i class="fa fa-phone"></i>
            {{ vendor.phone }} |
            <i class="fa fa-envelope-square"></i>
            {{ vendor.email }}
            <br />
            <i class="fa fa-map-marker"></i>
            {{ vendor.address1 }}
            <span v-if="vendor.address2">{{ vendor.address2 }}</span>
            <br />
            {{ vendor.city.name+', '+vendor.country }}
          </p>
        </div>
        <div class="column footer-logo" v-show="!embedSystem">
          BUILT BY |
          <i class="icon-mks icon-logo-precompro"></i>
          {{versionApp}}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data () {
    return {
      dialog: false,
      sendReserva: false,
      isFullPage: true
    };
  },
  props: ['value', 'isFreeFooter'],
  mounted () {
  },
  methods: {
    rgbaBackground (color) {
      if (color === null || this.embedSystem) {
        return 'transparent !important';
      }
      if (color.length > 6) {
        return `${color} !important`;
      }
      if (color.length <= 4) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        color = color.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });
      }
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      const rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
      return `rgba(${rgb['r']}, ${rgb['g']}, ${rgb['b']}, 0.7) !important`;
    },
    setKeyEventsAndroid () {
      setTimeout(() => {
        const ele = document.getElementById('emailTest1');
        const ele2 = document.getElementById('emailTest2');
        const ele3 = document.getElementById('emailTest3');
        if (ele != null) {
          ele.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele2 != null) {
          ele2.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
        if (ele3 != null) {
          ele3.addEventListener('textInput', (event) => {
            const char = event.data; // In our example = "a"
            if (char.indexOf(' ') >= 0) {
              event.preventDefault();
            }
            const keyCode = char.charCodeAt(0); // a = 97
            if (keyCode === 32) {
              event.preventDefault();
            }
          });
        }
      }, 800);
    },
    openSocial (social) {
      if (social === 'facebook') {
        window.open(this.vendor.facebook);
      } else if (social === 'twitter') {
        window.open(this.vendor.twitter);
      } else if (social === 'instagram') {
        window.open(this.vendor.instagram);
      } else if (social === 'whatsapp') {
        window.open(`https://wa.me/${this.vendor.whatsapp}`);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  #siguienteButton {
    margin-left: 0;
    border-radius: 5px;
    width: 95%;
    margin-left: 3%;
    display: flex;
    text-align: center;
    justify-content: center;
    bottom: 10px;
    backface-visibility: hidden;
  }
  .footer:not(.noPaddingAux) {
    padding-bottom: 50px !important;
  }
}
.footer-logo {
  display: flex;
}
.ajustNoShow {
  border: unset;
}
</style>
