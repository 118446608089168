<template>
  <div
    class="total-container"
    v-if="
      validateMareaSpecial() &&
      reservationInfo.typeReservation !== 'Rumba' &&
      reservationInfo.typeReservation !== 'Cena' &&
      reservationInfo.typeReservation !== 'Pombo' &&
      reservationInfo.typeReservation !== 'Brunch' &&
      !reservationInfo.decoration &&
      !reservationInfo.isEvent
    "
  >
    <TooltipTYC @showTerms="$emit('showTerms')"/>
    <p class="final-total-right">{{ $t("total") }}:</p>
    <p class="final-total-right">
      {{ (payAdultMarea * reservationInfo.adult + payBoyMarea * reservationInfo.boy) | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
  </div>
</template>
<script>
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';

export default {
  props: ['reservationInfo', 'payAdultMarea', 'payBoyMarea'],
  components: {
    TooltipTYC
  },
  methods: {
    validateMareaSpecial () {
      if (
        this.vendor.id === 231 &&
        (this.payAdultMarea > 0 || this.payBoyMarea > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
