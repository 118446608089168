<template>
    <div id="modalImageDecoration" :style="'background-image: linear-gradient(#0000004d, #0000004d), url('+ img +');'">
        <i @click="closeModal()" class="icons icon_close_x close" aria-hidden="true"></i>
    </div>
</template>
<script>
export default {
  props: ['img'],
  methods: {
    closeModal () {
      this.$emit('close');
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
#modalImageDecoration {
  border-radius: 15px;
  padding: 20px 40px 10px 40px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 953px;
  height: 716px;
  position: relative;
  margin: auto;
  .icon_close_x {
    -webkit-mask-image: url(../../assets/images/icons/decoration/icon_close_x.svg);
    mask-image: url(../../assets/images/icons/decoration/icon_close_x.svg);
    width: 15px;
    height: 15px;
    background: #fff;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    display: flex;
    cursor: pointer;
  }
  .close {
    font-weight: 900;
    color: white;
    font-size: 25px;
    right: 35px;
    top: 21px;
    position: absolute;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    width: 881px;
    max-width: 100%;
    height: 662px;
    max-height: 100vh;
  }
  @media screen and (max-width: 865px) {
    width: 709px;
    height: 662px;
  }
  @media screen and (max-width: 600px) {
    width: 382px;
    height: 385px;
  }
}
</style>
