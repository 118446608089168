<template>
  <div class="flex-container" v-if="reservationInfo.isEvent">
    <span class="item-left-total">
      <span class="checkout-item-subtitle">{{ $t('event') }}</span>
      <p class="item-small-total">
        {{(parseInt(reservationInfo.balance) / parseInt(reservationInfo.people)) | currency("$ ", 0, { thousandsSeparator: "," })}}
        * x{{ reservationInfo.people }} pax
      </p>
    </span>
    <p class="item-right-total">
      {{reservationInfo.balance | currency("$ ", 0, { thousandsSeparator: "," })}}
    </p>
  </div>
</template>
<script>
export default {
  props: ['reservationInfo']
};
</script>
