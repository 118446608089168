var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.reservationInfo.typeReservation !== 'Rumba' &&
    _vm.reservationInfo.typeReservation !== 'Cena' &&
    _vm.reservationInfo.typeReservation !== 'Pombo' &&
    _vm.reservationInfo.typeReservation !== 'Brunch' &&
    _vm.reservationInfo.decoration &&
    !_vm.reservationInfo.isEvent
  )?_c('div',{staticClass:"total-container"},[_c('TooltipTYC',{on:{"showTerms":function($event){return _vm.$emit('showTerms')}}}),_c('p',{staticClass:"final-total-right"},[_vm._v(_vm._s(_vm.$t("total"))+"  ")]),(_vm.reservationInfo.people >= _vm.params.minimumChargedPeople)?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.params.purchaseCost * _vm.reservationInfo.people + _vm.decorationPay),"$ ", 0, { thousandsSeparator: "," }))+" ")]):(_vm.getPayReservation())?_c('p',{staticClass:"final-total-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.getPayReservationValue() * _vm.reservationInfo.people + _vm.decorationPay),"$ ", 0, { thousandsSeparator: "," }))+" ")]):_c('p',{staticClass:"final-total-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.decorationPay,"$ ", 0, { thousandsSeparator: "," })))])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }