<template>
  <div>
    <div class="paymentCard-wompi">
      <form @submit.prevent="validateBeforePse" class="paymentCard-wompi-form">
        <div class="paymentCard-wompi-form__container-data ">
          <span class="field-custom-validation-wompi" style="width:100%;">
            <div class="paymentCard-wompi-form__container-data__fields paymentCard-wompi-form__container-data__fields__pse-wompi aux-field-name">
              <div class="">{{$t('giftCardView.payment.text38')}}</div>
                <div
                  class="field paymentCard-wompi-form__container-data__fields__pse-wompi__bank"
                >
                  <Selector
                    :options="bank"
                    :placeHolder="$t('giftCardView.payment.text38')"
                    @value="selectBank"
                  />
                  <p class="help is-danger" v-if="validateBank">{{validateBank}}</p>
                </div>
            </div>
          </span>
          <h3 class="title-type">{{$t('information')}}</h3>
          <div class="paymentCard-wompi-form__container-data__fields paymentCard-wompi-form__container-data__fields__pse-wompi">
            <b-field
                :message="errors.first('name')"
                :type="{'is-danger': errors.has('name')}"
                class="paymentCard-wompi-form__container-data__fields__pse-wompi__credit-card-name"
              >
                <b-input
                  :placeholder="$t('cardholder')"
                  autofocus
                  expanded
                  icon-pack="fa"
                  id="name"
                  name="name"
                  type="text"
                  v-model="pse.name"
                  v-validate="'required|alpha_spaces'"
                ></b-input>
            </b-field>
            <span class="field-custom-validation-wompi aux-document-field">
              <b-field
                :message="errors.first('identification')"
                :type="{'is-danger': errors.has('identification')}"
                class="paymentCard-wompi-form__container-data__fields__pse-wompi__document-number"
                :class="{'padding-aux' : validateDoc}"
              >
                <InputC
                  :options="typePerson"
                  :placeHolder="$t('idNumber')"
                  :valueInput="'' + pse.user_legal_id"
                  :start="typePerson[0].value"
                  @valueChange="changeTypePersonAndIdentity"
                  selector
                />
                <p class="help is-danger" v-if="validateDoc">{{validateDoc}}</p>
              </b-field>
              <span class="icon is-right has-text-danger custom-validation-danger" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
            </span>

            <span class="field-custom-validation-wompi aux-phone-field">
              <b-field
                :message="errors.first('phone')"
                :type="{'is-danger': errors.has('phone')}"
                class="paymentCard-wompi-form__container-data__fields__pse-wompi__phone"
                :class="{'padding-aux-2' : validatePhone}"
              >
                <inputSelector
                  @value="changedValuePhone"
                  :required="true"
                  :defaultFlags="true"
                  :defaultValidations="false"
                />
                <p class="help is-danger" v-if="validatePhone">{{validatePhone}}</p>
              </b-field>
              <span class="icon is-right has-text-danger custom-validation-danger" v-if="validatePhone"><i class="fa fa-exclamation-circle"></i></span>
            </span>

            <b-field
              :message="errors.first('email')"
              :type="{'is-danger': errors.has('email')}"
              class="paymentCard-wompi-form__container-data__fields__pse-wompi__email"
            >
              <b-input
                :placeholder="$t('email')"
                autofocus
                expanded
                icon-pack="fa"
                id="email"
                name="email"
                type="text"
                v-model="pse.email"
                v-validate="'required|email'"
              ></b-input>
            </b-field>
          </div>
            <div class="paymentCard-wompi-form__container-data__logo-container type-people-switch-checkout">
              <div class="person-type-option">
                <div>
                  <div>{{ $t('naturalPerson') }}</div>
                  <b-switch
                    name="typePeople"
                    type="is-custom"
                    v-model="switch_type_0"
                    size="is-small"
                    @input="validatePersonType(0)"
                  ></b-switch>
                </div>
                <div>
                  <div>{{ $t('legalPerson') }}</div>
                  <b-switch
                    name="association"
                    type="is-custom"
                    v-model="switch_type_1"
                    size="is-small"
                    @input="validatePersonType(1)"
                  ></b-switch>
                </div>
                <span
                  class="text-danger"
                  v-show="errors.has('typePeople')"
                >{{ errors.first('typePeople') }}</span>
              </div>
              <div
                :class="{'padding-500':!reservation.isFree}"
                class="bottom-text-r"
                style="margin-right: 0px;"
                v-if="!reservation.isFree"
              >
                <div>{{$t('securePayments')}}</div>
                <div class="logo-fix-container">
                  <i class="img-wompi-svg"></i>
                </div>
              </div>
          </div>
        </div>
        <div class="paymentCard-wompi-form__footer-wompi-terms">
          <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
            <div class="copy-terms">
              <b-checkbox class="terms-label" v-model="authTerms"></b-checkbox>
              <div>
                {{$t('termsReservationPay1')}}
                <a
                  @click="showTerms2 = true"
                  class="link-terms"
                >{{$t('termsAndCondiW')}}</a>
                {{$t('termsReservationPay2')}}
                <a
                  @click="showTerms = true"
                  class="link-terms"
                >{{$t('privacyPolicyW')}}*</a>
                .
                {{$t('termsReservationPay3')}}
              </div>
            </div>
          </div>
          <div :class="{'pay': !reservation.isFree , 'free': reservation.isFree }" class="terms">
            <div class="copy-terms">
              <b-checkbox class="terms-label" v-model="authTermsWompi"></b-checkbox>
              <div>
                {{$t('termsPaymentWompi')}}
                <a
                  :href="linkTerms"
                  target="_blank"
                  class="link-terms"
                >{{$t('termsPaymentWompi1')}}</a>
                {{$t('termsPaymentWompi2')}}
              </div>
            </div>
          </div>
          <div id="modal-terms" v-if="showTerms">
            <b-modal :active.sync="showTerms">
              <div class="terms-body">
                <h3 class="title-modal">{{ $t('checkoutView.components.text15') }}</h3>
                <terms :fecha="'22 de Noviembre del 2019'"></terms>
              </div>
            </b-modal>
          </div>
          <div id="modal-terms" v-if="showTerms2">
            <b-modal :active.sync="showTerms2">
              <div class="terms-body">
                <h3 class="title-modal">{{ $t('checkoutView.components.text16') }}</h3>
                <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
              </div>
            </b-modal>
          </div>
          <div class="row">
            <button
              :class="{'opacity06':(pse.name === null || pse.name === ''||
                      pse.identification === null || pse.identification === '' ||
                      pse.phone === null || pse.phone === '' || validatePhone !== null ||
                      pse.email === null || pse.email === '' ||
                      pse.user_legal_id_type === null || pse.user_legal_id_type === '')}"
              :disabled="!authTerms"
              class="btn btn-pay"
              type="submit"
            >{{$t('pay')}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapGetters, mapActions } from 'vuex';
import terms from '@/components/Terms';
import terms2 from '@/components/Terms2';
import InputC from '@/components/customInput.vue';
import Selector from '@/components/Select/GeneralSelector.vue';
import inputSelector from '@/components/Select/inputs/inputSelectCheckout.vue';

export default {
  components: { terms, terms2, InputC, Selector, inputSelector },
  props: ['showMessageCountry', 'btnCheckedMainPlate'],
  data () {
    return {
      bank: [{ value: 0, name: 'Seleccione una entidad bancaria' }],
      typePerson: [
        { name: 'CC', value: 'CC' },
        { name: 'CE', value: 'CE' },
        { name: 'NIT', value: 'NIT' }
      ],
      pse: {
        financial_institution_code: null,
        name: null,
        email: null,
        user_legal_id: null,
        user_legal_id_type: null,
        user_type: 0,
        phone_number: null
      },
      switch_type_0: true,
      switch_type_1: null,
      authTermsWompi: true,
      linkTerms: '',
      acceptanceToken: '',
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      loadedInfo: false,
      redirectUrl: process.env.NODE_ENV === 'production' ? 'https://' + this.subDomain() + '.precompro.com/checkout' : 'https://dev.precompro.co/checkout',
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      validateBank: null,
      validatePhone: null,
      validateDoc: null,
      enableValidations: false
    };
  },
  computed: {
    ...mapGetters({
      reservation: reservationTypes.getters.reservationsUser,
      banks: whiteLabelTypes.getters.banks,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip
    })
  },
  methods: {
    ...mapActions({
      confirmReservation: reservationTypes.actions.confirmReservation
    }),
    selectPayment () {
      this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
    },
    customValidationsBeforeSubmit () {
      if (this.pse.financial_institution_code === null || this.pse.financial_institution_code === '' || this.pse.financial_institution_code === undefined) {
        this.validateBank = this.$t('checkoutValidations.bank.required');
      } else {
        this.validateBank = null;
      }
      if (this.pse.phone_number === null || this.pse.phone_number === '' || this.pse.phone_number === undefined) {
        this.validatePhone = this.$t('checkoutValidations.phone.required');
      }
      if (this.pse.user_legal_id === null || this.pse.user_legal_id === '' || this.pse.user_legal_id === undefined) {
        this.validateDoc = this.$t('checkoutValidations.phone.required');
      } else {
        this.validateDoc = null;
      }
    },
    validateBeforePse () {
      this.$validator.validateAll().then(result => {
        this.enableValidations = true;
        this.customValidationsBeforeSubmit();
        if (this.validateBank === null && this.validatePhone === null && this.validateDoc === null) {
          if (result) {
            const applyStrong = window.localStorage.getItem('applyStrong');
            if (applyStrong) {
              if (!this.btnCheckedMainPlate && applyStrong === true) {
                this.$buefy.snackbar.open({
                  duration: 5000,
                  message:
                    "<i class='fa fa-exclamation-circle alerticon'></i>Es necesario que selecciones el plato fuerte de cada persona para poder completar la reserva",
                  type: 'is-warning',
                  position: 'is-bottom',
                  actionText: 'X',
                  queue: false
                });
                return;
              }
            }
            if ((this.params.activeExperiencies === 1 && this.params.optionRequiredExperiencie === 1) && (!this.reservation.experiences || this.reservation.experiences.length === 0 || this.reservation.experiences === undefined || this.reservation.experiences == null) && !this.reservation.isEvent && window.localStorage.getItem('_invalid_alert_experience') === undefined) {
              this.$buefy.snackbar.open({
                duration: 6000,
                message: `<div class="custom_alert"><div class="custom_alert_icon"><i class="icon_alert_danger"></i></div><div><p>Es necesario que selecciones la experiencia de cada persona para poder completar la reserva</p></div></div>`,
                type: 'is-danger is-custom',
                position: 'is-top',
                actionText: ' ',
                queue: true
              });
              return;
            }
            this.proccessPse(this.card);
          }
        }
      });
    },
    proccessPse () {
      if (
        this.reservation.phone === '' ||
        this.reservation.phone === null ||
        !this.reservation.phone
      ) {
        this.$emit('edit-phone', true);
      } else if (this.showMessageCountry) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else if (this.pse.user_type === null) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message:
            "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un tipo de cliente",
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'X',
          queue: false
        });
      } else {
        let dataBrowser = this.userAgentBrowser().parsedResult;
        this.reservation.dataBrowser = dataBrowser;
        this.reservation.paymentProvider = 'wompi';
        this.reservation.balance = (this.reservation.balance + this.tip);
        this.reservation.totalBalance = (this.reservation.totalBalance + this.tip);
        this.reservation.tip = this.tip;
        this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
        this.confirmReservation({
          reservation: this.reservation,
          pse: this.pse,
          paymentProvider: 'wompi',
          'redirect_url': this.redirectUrl,
          'acceptance_token': this.acceptanceToken
        })
          .then(({ data }) => {
            if (data.data.code === 799 || data.data.code === 705) {
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>" + data.msg,
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
              return;
            }
            if (data.data.code === 200) {
              window.localStorage.setItem('transactionWompiFollowUpReservation', JSON.stringify({
                transactionId: data.data.transactionId,
                expiresAt: new Date().getTime() + 10 * 60000
              }));
              window.location.href = data.data.paymentUrl;
            } else {
              this.$buefy.snackbar.open({
                duration: 5000,
                message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>Operación fallida <br><strong>Ocurrió un error al intentar realizar el pago, por favor intenta de nuevo</strong> ",
                type: 'is-warning',
                position: 'is-bottom',
                actionText: 'X',
                queue: false
              });
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            }
          })
          .catch(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$buefy.dialog.alert({
              message: "<i class='fa fa-exclamation-circle alerticon'></i>Ocurrio un error al procesar la petición con la pasarela de pago <br><strong>Intenta realizar el pago de nuevo</strong> ",
              confirmText: 'Aceptar',
              onConfirm: () => {
                window.location.reload();
              }
            });
          });
        if (this.reservation.isEditWhiteLabel != null) {
          this.reservation.isEditWhiteLabel = null;
        }
      }
    },
    selectBank (selected) {
      this.pse.financial_institution_code = selected;
      if (this.pse.financial_institution_code !== undefined && this.pse.financial_institution_code !== null && this.pse.financial_institution_code !== '') {
        this.validateBank = null;
      }
    },
    getWompiBanks () {
      return this.$http.get('/payment/wompi/banks/' + this.vendorId);
    },
    getAcceptanceToken () {
      return this.$http.get('/payment/wompi/acceptance-token/' + this.vendorId);
    },
    validatePersonType (personType) {
      if (!this.switch_type_1 && !this.switch_type_0) {
        this.$nextTick(() => {
          this.switch_type_0 = true;
          this.pse.user_type = 0;
        });
      }
      if (personType === 0) {
        this.switch_type_1 = false;
        this.pse.user_type = 0;
      } else if (personType === 1) {
        this.switch_type_0 = false;
        this.pse.user_type = 1;
      }
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identificationTypes = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    },
    changeTypePersonAndIdentity (personTypeId) {
      if (this.enableValidations) {
        if (personTypeId.inputValue !== undefined && personTypeId.inputValue !== null && personTypeId.inputValue !== '') {
          this.validateDoc = null;
        } else {
          this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        }
      }
      this.pse.user_legal_id_type = personTypeId.selectorValue;
      this.pse.user_legal_id = personTypeId.inputValue;
    },
    validatePhoneRegex (phone, indicative) {
      switch (true) {
        case !phone:
          this.validatePhone = this.$t('checkoutValidations.phone.required');
          break;

        case !/^\d+$/.test(phone):
          this.validatePhone = this.$t('checkoutValidations.phone.numeric');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length < 7:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative !== 57 && indicative !== 507 && phone.length > 11:
          this.validatePhone = this.$t('checkoutValidations.phone.max');
          break;

        case indicative === 57 && phone.length !== 10:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;

        case indicative === 507 && phone.length < 7 && phone.length > 8:
          this.validatePhone = this.$t('checkoutValidations.phone.min');
          break;
        default:
          this.validatePhone = null;
          break;
      }
    },
    changedValuePhone (values) {
      this.validatePhoneRegex(values.inputValue, values.countrieInfo.indicativo);
      this.pse.phone_number = values.inputValue;
    }
  },
  mounted () {
    this.$emit('validateCheck');
    const bankPromise = this.getWompiBanks()
      .then(({ data }) => {
        this.bank = data.data.map(item => {
          return { name: item.financial_institution_name, value: item.financial_institution_code };
        });
      });
    const acceptanceTokenPromise = this.getAcceptanceToken()
      .then(({ data }) => {
        this.linkTerms = data.data.permalink;
        this.acceptanceToken = data.data.acceptance_token;
      });
    const promises = [bankPromise, acceptanceTokenPromise];
    Promise.all(promises).finally(() => {
      this.loadedInfo = true;
      this.redirectUrl = window.location.href;
    });
    if (this.showMessageCountry) {
      this.$buefy.snackbar.open({
        duration: 5000,
        message:
          "<i class='fa fa-exclamation-circle alerticon'></i>Debe seleccionar un país como indicativo, en los datos del cliente",
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'X',
        queue: false
      });
    }
    this.getIdentification();
  },
  watch: {
    authTerms () {
      this.$emit('validateCheck', this.authTerms);
    }
  }
};
</script>
<style lang="scss">
.hsy-dropdown > .list {
  width: 100% !important;
}
.text-danger {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #ff3860;
}

.rigth-border {
  border-right: 1px solid #ccc !important;
}
.btn-pay {
  background: var(--btnBgReservationFooter);
  color: var(--btnColorReservationFooter) !important;
}
.btn-pay:disabled {
  background-color: var(--btnBgReservationFooter) !important;
  opacity: 0.5;
  color: var(--btnColorReservationFooter)!important;
  border: 1px solid var(--colorCheckout)  !important;
}
.btn-pay-content {
  width: 100%;
  position: relative;
  button {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
  }
}
.list {
  height: 20vh;
  overflow: scroll;
}

.opacity06 {
  opacity: 0.6;
}
.img-card {
  height: 9rem;
}

select option {
  padding: 15px 0px;
}

@media (min-width: 768px) {
  .border-left {
    border-left: 1px solid lightgray;
  }
  // select {
  //   text-align-last: right;
  // }
  option {
    // direction: rtl;
    font-size: 12px;
  }
  .inputTrans {
    text-align: right;
  }
}
.v-select .dropdown-toggle {
  height: 32px;
}
</style>
<style lang="scss">
  @import "@/assets/styles/payment/_paymentCardWompi.scss";
</style>
