<template>
    <div class="flex-container" v-if="reservationInfo.decoration">
        <div class="fullInfoDecoration">
            <span class="item-left-total">
                <span class="checkout-item-subtitle">{{ $t('decoration') }}</span>
                <p class="item-small-total">
                    {{ decorationPay | currency('$ ', 0, { thousandsSeparator: ',' }) }}* &nbsp; &nbsp;
                </p>
            </span>
            <p class="item-right-total">{{ decorationPay | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
        </div>
    </div>
</template>
<script>
export default {
  props: ['reservationInfo', 'decorationPay']
};
</script>
