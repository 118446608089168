<template>
  <div id="tooltipTyc" >
    <i class="icon-mks icon-help-tooltip" @click="openTooltip(!tooltipTYC)" @mouseover="openTooltip(true)"></i>
    <div class="container-tooltip-tyc" v-if="tooltipTYC" @mouseleave="openTooltip(false)">
      <i class="icon-mks icon_alert_cl" @click="openTooltip(false)"></i>
      <div class="container-tooltip-tyc__bar-content" :style="`${calculateCancelTimeReservation().isPreviousDay ? 'margin-top:35px;' : ''}`">
        <div class="container-tooltip-tyc__bar-content__bar"></div>
        <span class="container-tooltip-tyc__bar-content__hour-cancelation" v-if="!calculateCancelTimeReservation().isPreviousDay">{{calculateCancelTimeReservation().formatHour}}</span>
        <div v-else class="container-tooltip-tyc__bar-content__hour-cancelation container-tooltip-tyc__bar-content__hour-cancelation-with-date">
          <span>{{calculateCancelTimeReservation().formatHour}}</span>
          <span>{{calculateCancelTimeReservation().formatDate}}</span>
        </div>
        <div class="container-tooltip-tyc__bar-content__hour-reservation">
          <b>{{new Date(reservation.dateTime) | moment('timezone', vendorTimezone, "hh:mm a")}}</b>
          <span>{{$t('checkoutView.components.text36')}}</span>
        </div>
        <p class="container-tooltip-tyc__bar-content__general-copy">{{$t('checkoutView.components.text35')}}</p>
      </div>
      <div class="container-tooltip-tyc__copy-tyc">
        <h3>{{$t('checkoutView.components.text34')}}</h3>
        <div>
          {{hoursTimeCancelation()}} <b>{{$t('checkoutView.components.text38')}}</b><b @click="$emit('showTerms')" style="text-decoration: underline; cursor:pointer;">{{$t('checkoutView.components.text39')}}</b>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';

export default {
  data () {
    return {
      modalTYC: false
    };
  },
  computed: {
    ...mapGetters({
      tooltipTYC: whiteLabelTypes.getters.tooltipTYC,
      reservation: reservationTypes.getters.reservationsUser
    }),
    hoursTimeCancelation () {
      return () => {
        const hours = Math.floor((this.params.cancelledTime / 3600000));
        return this.$t('checkoutView.components.text37', { hours, plur: `${hours > 1 || hours === 0 ? '(s) ' : ' '}` });
      };
    }
  },
  methods: {
    openTooltip (status) {
      this.$store.commit(
        whiteLabelTypes.mutations.setTooltipTYC,
        status
      );
    },
    calculateCancelTimeReservation () {
      const originalDate = this.reservation.dateTime; // Fecha original en milisegundos
      const miliseconds = this.params.cancelledTime; // 4 horas en milisegundos
      const newDate = originalDate - miliseconds;

      // Convertir ambas fechas a formato 'YYYY-MM-DD' para comparar solo el día
      const originalDateDay = this.$moment(originalDate).format('YYYY-MM-DD');
      const newDateDay = this.$moment(newDate).format('YYYY-MM-DD');

      // Comparar si el día de la nueva fecha es anterior al día de la fecha original
      const isPreviousDay = this.$moment(newDateDay).isBefore(originalDateDay);

      const formatHour = this.$moment(newDate).format('hh:mm a');
      const formatDate = this.$moment(newDate).format('DD/MM/YY');

      return {
        formatHour,
        formatDate,
        isPreviousDay
      };
    }
  }
};
</script>

<style lang="scss">
  #tooltipTyc{
    position: relative;
    .icon-help-tooltip{
      margin-top: 4px;
      margin-right: 8px;
      cursor: pointer;
    }
    .container-tooltip-tyc{
      border-radius: 8px;
      background: white;
      color: black;
      width: 397px;
      min-height: 174px;
      position: absolute;
      padding: 14px 18px;
      text-align: center;
      z-index: 38;
      left: -15.9rem;
      bottom: 1.9em;
      box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.2);
      .icon_alert_cl{
        position: absolute;
        right: 8px;
        top: 8px;
        background: black;
        width: 10px;
        height: 10px;
        margin: 0;
        cursor: pointer;
      }
      &::after{
        position: absolute;
        content: "";
        pointer-events: none;
        z-index: 38;
        top: 100%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(3.56em);
        border-top: 8px solid white;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
      }
      &__bar-content{
        margin-top: 20px;
        margin-bottom: 27px;
        margin-left: 34px;
        width: 248px;
        height: 12px;
        border-radius: 6px;
        background: linear-gradient(269deg, #FD0215 14.57%, #FFB740 28.58%, #FFE36C 44.63%, #CAEF4F 66.03%, #B7ED4C 70.36%, #01D92E 89.21%);
        position: relative;
        &__hour-cancelation{
          position: absolute;
          font-size: 10px;
          top: -18px;
          right: 44px;
          &-with-date{
            position: absolute;
            font-size: 10px!important;
            top: -36px;
            display: grid;
            &::after{
              top: 22px !important;
              height: 39px !important;
              width: 29px !important;
              left: -5px !important;
            }
            &::before{
              top: 46px !important;
              width: 31px !important;
              height: 12px !important;
              left: -15em !important;
            }
          }
          &::after{
            content: "";
            height: 32px;
            width: 29px;
            position: absolute;
            font-size: 11px;
            border: 1px solid #374957;
            border-style: dashed;
            top: 10px;
            left: -6px;
            transform: scale(0.8);
            border-top: none;
            border-left: none;
          }
          &::before{
            content: "";
            height: 12px;
            width: 32px;
            position: absolute;
            font-size: 11px;
            border: 1px solid #374957;
            border-style: dashed;
            top: 29px;
            left: -15em;
            transform: scale(0.85);
            border-right: none;
            border-top: none;
          }
        }
        &__hour-reservation{
          display: grid;
          position: absolute;
          right: -46px;
          top: -2px;
          line-height: 0.95;
          b{
            font-size: 10px;
          }
          span{
            font-size: 8px;
            font-weight: 400;
          }
        }
        &__general-copy{
          position: absolute;
          font-size: 8px;
          font-weight: 400;
          bottom: -13px;
          left: 30px;
        }
      }
      &__copy-tyc{
        h3{
          font-weight: 600;
          margin-bottom: 12px;
          font-size: 12px;
        }
        > div{
          font-size: 12px;
          font-weight: 400;
          b{
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px){
    #tooltipTyc{
    position: relative;
    .icon-help-tooltip{
      margin-top: 4px;
      margin-right: 8px;
      cursor: pointer;
    }
    .container-tooltip-tyc{
      left: -16.6rem;
      &::after{
        transform: translateX(4.2em);
      }
    }
  }
  }
</style>
