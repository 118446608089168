<template>
  <div
    class="total-container"
    v-if="
      reservationInfo.typeReservation !== 'Rumba' &&
      reservationInfo.typeReservation !== 'Cena' &&
      reservationInfo.typeReservation !== 'Pombo' &&
      reservationInfo.typeReservation !== 'Brunch' &&
      decorationPay > 0 &&
      experiencePay > 0 &&
      !reservationInfo.isEvent
    "
  >
    <TooltipTYC @showTerms="$emit('showTerms')"/>
    <p class="final-total-right">{{ $t("total") }}: &nbsp;</p>
    <p class="final-total-right" v-if="reservationInfo.people >= params.minimumChargedPeople">
      {{ (params.purchaseCost * reservationInfo.people + decorationPay + experiencePay) | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
    <p class="final-total-right" v-else-if="getPayReservation()">
      {{ (getPayReservationValue() * reservationInfo.people + decorationPay + experiencePay) | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
    <p class="final-total-right" v-else>
      {{ decorationPay + experiencePay | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
  </div>
</template>
<script>
import LocalStorage from 'store';
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';

export default {
  props: ['reservationInfo', 'decorationPay', 'experiencePay'],
  components: {
    TooltipTYC
  },
  methods: {
    getPayReservation () {
      let data = false;
      let info = LocalStorage.get('payReservations');
      if (info !== undefined) {
        data = true;
      }
      return data;
    },
    getPayReservationValue () {
      let data = 0;
      let info = LocalStorage.get('payReservations');
      if (info !== undefined) {
        data = parseInt(info);
      }
      return data;
    }
  }
};
</script>
