<template>
  <div @mouseleave="opened = false" id="select-input-personalizado-checkout">
    <b-field :message="errors.first('number')" :type="{ 'is-danger': errors.has('number') }">
      <div :class="{openList:opened}" class="select-input-personalizado">
        <div
          :class="{'select-input-error': errors.has(field)}"
          @click="showList()"
          class="select-input-personalizado-icon-selector"
        >
          <!--BANDERAS-->
          <div class="countriesSelectorContainer">
            <img
              :src="countrieInfo.bandera"
              @error="imageNotFound"
              alt="bandera"
              class="select-input-personalizado-bandera"
            />
            <p>(+{{countrieInfo.indicativo}})</p>
          </div>
          <!--FIN BANDERAS-->
          <div class="dropdown-list">
            <span class="icon-mks icon_arrow_select less">{{!opened? 'expand_more': 'expand_less'}}</span>
          </div>
          <div class="divisor"></div>
        </div>
        <div class="select-input-personalizado-center" v-if="defaultValidations">
          <i class="icon-cellphone-checkout"></i>
          <input
            autocomplete="off"
            class="select-input-personalizado-center-text"
            name="number"
            placeholder="Celular"
            type="number"
            v-model="value"
            v-validate="validatedRules"
          />
          <span class="fa fa-exclamation-circle has-text-danger" v-show="errors.has('number')"></span>
        </div>
        <div class="select-input-personalizado-center" v-else>
          <i class="icon-cellphone-checkout"></i>
          <input
            autocomplete="off"
            class="select-input-personalizado-center-text"
            name="number"
            :placeholder="$t('mobile')"
            type="number"
            v-model="value"
          />
        </div>
      </div>
      <div :style="{display: opened ? 'flex' : 'none', zIndex: 2}" class="dropdown-list-content">
        <div class="filterFlagsContainer">
          <input
            style="height:30px!important"
            @input="(e)=>filter=e.target.value "
            class="filterFlags"
            placeholder="Escriba el país o el indicativo"
            type="text"
          />
        </div>
        <div
          :key="'countries#' + IType"
          @click="()=>{valueSelector = itemType.image; showList(); changedPais(itemType)}"
          class="dropdown-list-content-item"
          v-for="(itemType, IType) in filteredCountries"
        >
          <div class="dropdown-list-content-item-left">
            <img :src="itemType.image" @error="imageNotFound" alt="countries" />
          </div>
          <div class="dropdown-list-content-item-right">(+{{itemType.value}}) {{ itemType.label }}</div>
        </div>
      </div>
    </b-field>
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';

export default {
  props: {
    iconLeft: {
      type: String
    },
    iconRight: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    fieldSelector: {
      type: String
    },
    type: {
      type: String
    },
    typeData: {
      type: String
    },
    rules: {
      type: String
    },
    required: {
      type: Boolean
    },
    selector: {
      type: Boolean
    },
    indicative: {
      type: String
    },
    flag: {
      type: String
    },
    errorShow: {
      type: Boolean
    },
    initDocument: {
      type: String
    },
    initInputValue: {
      type: String
    },
    initIndicativo: {
      type: Number
    },
    initInfo: {
      type: Object
    },
    formInfo: {
      type: Object
    },
    defaultFlags: {
      type: Boolean,
      value: false
    },
    defaultValidations: {
      type: Boolean,
      value: false
    }
  },
  data () {
    return {
      filter: '',
      value: '',
      countries: [],
      opened: false,
      option: {
        value: 57,
        label: 'Colombia',
        image: 'https://api.precompro.com/banderas/co.png',
        selected: true
      },
      validatedRules: 'required|numeric|min:7|max:11',
      validated: this.required || false,
      error: null,
      countrieInfo: {
        bandera: 'https://api.precompro.com/banderas/co.png',
        indicativo: 57,
        pais: 'Colombia'
      }
    };
  },
  mounted () {
    setTimeout(() => {
      this.value = this.initInputValue;
      this.listCountries();
      if (this.defaultFlags) {
        this.indicativeParam(this.params.indicativeRegion ? this.params.indicativeRegion : 57);
      } else {
        this.indicativeParam(this.initIndicativo, this.flag);
      }
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    }, 1000);
  },
  methods: {
    formatFilter () {
      return this.filter.toLowerCase();
    },
    showList () {
      this.opened = !this.opened;
    },
    imageNotFound (e) {
      e.target.src = 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg';
    },
    listCountries () {
      const data = [];
      const indicative = null;
      const flag = null;
      _.forEach(BanderaPais, function (item) {
        const info = callingCode.find(i => i.country === item.country);
        if (info) {
          if (info.calling_code !== null) {
            const image = item.abbreviation ? 'https://api.precompro.com/banderas/' + item.abbreviation.toLowerCase() + '.png' : null;
            if (indicative != null && indicative === info.calling_code && flag != null && flag === image && info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image,
                selected: true
              });
            } else if (info.country !== 'Bouvet Island') {
              data.push({
                value: info.calling_code,
                label: info.country,
                image: image
              });
            }
          }
        }
      });
      this.countries = data;
    },
    indicativeParam (indicative, flag = null) {
      let selectIndicative = null;
      if (flag !== null) {
        selectIndicative = this.countries.find(value => parseInt(value.value) === parseInt(indicative) && value.image === flag);
      } else {
        selectIndicative = this.countries.find(value => parseInt(value.value) === parseInt(indicative));
      }
      this.option = selectIndicative;
      this.valueSelector = selectIndicative.image;
      this.changedPais(this.option);
    },
    changedPais (indicative) {
      this.option = indicative;
      let aux2 = null;
      const aux3 = indicative.label;
      const ind = indicative.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country;
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.countrieInfo = { pais: indicative.label, indicativo: indicative.value, bandera: aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null };
      this.validateTelIndicative(indicative.value);
    },
    validateTelIndicative (indicative) {
      const paisId = (indicative === null) ? 57 : parseInt(indicative);
      switch (paisId) {
        case 57:
          this.validatedRules = 'required|numeric|min:10|max:10';
          this.validated = true;
          break;
        case 507:
          this.validatedRules = 'required|numeric|min:7|max:8';
          this.validated = true;
          break;
        default:
          this.validatedRules = 'required|numeric|min:7|max:11';
          this.validated = true;
          break;
      }
    },
    isValidTel () {
      setTimeout(() => {
        if (this.value !== null && this.value !== '' && this.value !== undefined && !isNaN(this.value)) {
          if (this.errors.first('input') !== undefined) {
            this.error = 'Por favor ingrese un Teléfono válido';
          } else {
            this.error = null;
          }
        } else {
          this.error = null;
        }
      }, 100);
    },
    filteredCountriesMethod () {
      return this.countries.filter((country) =>
        country.label.toLowerCase().includes(this.formatFilter()) || ('+' + country.value.toString()).includes(this.formatFilter())
      );
    }
  },
  watch: {
    countrieInfo () {
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    },
    value () {
      this.$emit('value', { countrieInfo: this.countrieInfo, inputValue: this.value });
    },
    initInputValue () {
      this.value = this.initInputValue;
    }
  },
  computed: {
    filteredCountries () {
      if (this.filter === '') {
        return this.countries;
      } else {
        return this.filteredCountriesMethod();
      }
    }

  }
};
</script>
<style lang="scss">
#select-input-personalizado-checkout {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    outline: unset;
  }
}

.filterFlagsContainer {
  background-color: transparent;
  padding: 2px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  overflow: hidden;

  input {
    margin: 0 10px;
    padding: 0 10px!important;
    height: 40px!important;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    border: 0!important;
    background-color: var(--bgColorStepper)!important;
    color: var(--bgColorCheckout)!important;
    font-size: 14px;
    &::placeholder{
      color: var(--bgColorCheckout)!important;
      font-size: 12px;
    }
    @media screen and (min-width: 650px) and (max-width: 1025px) {
      font-size: 10.3px;
    }
  }
  span {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 15px;
    height: 14px;
    position: relative;
    right: 3px;
  }
}
#select-input-personalizado-checkout {
  z-index: 20;
  width: 100%;
  .field{
    border: 0!important;
  }
  .help.is-danger{
    font-size: 10px;
  }
  .filterFlags {
    border: unset;
    outline: none;
  }
  .icon_arrow_select{
    width: 10px;
    height: 6px;
    margin-right: 8px;
    margin-top: 2px!important;
  }
  position: relative;
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    /* margin-bottom: 5px; */
  }
  .icon-cellphone-checkout{
    background-color: var(--colorCheckout);
  }
  .dropdown-list-content {
    position: absolute;
    top: 30px;
    left: 0px;
    background-color: var(--bgColorCheckout);
    width: 100%;
    overflow-y: auto;
    max-height: 180px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    z-index: 50;
    flex-direction: column;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-top: solid 1px transparent;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--bgColorCheckout);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--colorCheckout) !important;
      box-shadow: unset !important;
      border: unset;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-height: 28px;
      max-height: 29px;
      padding: 0px 5px;
      cursor: pointer;
      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 32px;
        margin: 5px;
        width: 30px;
        font-size: 13px;
        img {
          width: 20px;
          height: 20px;
          border-radius: 99%;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        width: 100%;
        font-size: 12px;
        color:var(--colorCheckout);
      }
      &:hover {
        background-color: #ddd;
      }
    }
  }
  .select-input-personalizado {
    padding: 0 10px;
    display: flex;
    width: 100%;
    margin: 4px 0;
    border: unset;
    border-radius: 6px;
    overflow: hidden;
    input, select, option{
      font-size: 14px;
        color:var(--colorCheckout);
        &::placeholder{
          color:var(--colorCheckout);
        }
    }
    &.openList {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      position: relative;
      &::after {
        content: " ";
        display: block;
        background-color: transparent;
        height: 1px;
        width: 90%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0px;
      }
    }
    &-icon-selector {
      cursor: pointer;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      height: 20px;
      width: fit-content;
      background-color: var(--bgColorCheckout);
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      @media screen and (max-width: 600px) {
        width: 90px;
      }
      .dropdown-list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 9px;
        img {
          cursor: pointer;
          width: 15px;
          height: 7px;
        }
      }
      .divisor {
        width: 1px;
        height: 28px;
        background-color: var(--colorCheckout);
        margin-left: 2px;
      }
    }
    .with-selector {
      border-left: none;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
    &-center {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 100%;
      position: relative;
      &-text {
        width: 100%;
        height: 20px;
        border-left: none;
        border: unset;
        border-right: none;
        padding-left: 25px;
        text-align: left;
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 60px;
      border-left: none;
      position: relative;

      &-item {
        display: flex;
        // border-left: 1px solid rgba(68, 75, 87, .5);
        width: 60px;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
      }
    }
    .borderRight {
      .select-input-personalizado-center-text {
        margin-right: 3px;
      }
    }
    &-bandera {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      cursor: pointer;
    }
    &-text {
      height: 25px;
      width: 25px;
      cursor: pointer;
    }
  }
  .select-input-item {
    border: none;
    border-right: 1px solid rgba(68, 75, 87, 0.5);
    height: 30px;
    width: 50px;
  }
  .w-100px {
    width: 100px;
  }
}
.countriesSelectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: max-content;
  p {
    font-size: 14px;
  }
}
.cellphone {
  position: absolute;
  left: 0;
  font-size: 20px;
}
span.error {
  color: red;
  position: absolute;
  right: 6px;
  top: 0;
  left: unset;
  bottom: 0;
  margin: auto;
  font-size: 21px;
  width: fit-content;
  height: 22px;
  transform: translateY(1px);
}
</style>
