<template>
  <div
    class="total-container"
    v-if="
      reservationInfo.typeReservation !== 'Rumba' &&
      reservationInfo.typeReservation !== 'Cena' &&
      reservationInfo.typeReservation !== 'Pombo' &&
      reservationInfo.typeReservation !== 'Brunch' &&
      reservationInfo.decoration &&
      !reservationInfo.isEvent
    "
  >
    <TooltipTYC @showTerms="$emit('showTerms')"/>
    <p class="final-total-right">{{ $t("total") }} &nbsp;</p>
    <p class="final-total-right" v-if="reservationInfo.people >= params.minimumChargedPeople">
      {{ (params.purchaseCost * reservationInfo.people + decorationPay) | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
    <p class="final-total-right" v-else-if="getPayReservation()">
      {{ (getPayReservationValue() * reservationInfo.people + decorationPay) | currency("$ ", 0, { thousandsSeparator: "," }) }}
    </p>
    <p class="final-total-right" v-else>{{ decorationPay | currency("$ ", 0, { thousandsSeparator: "," }) }}</p>
  </div>
</template>
<script>
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';
export default {
  props: ['reservationInfo', 'decorationPay'],
  components: {
    TooltipTYC
  },
  methods: {
    getPayReservation () {
      let data = false;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = true;
      }
      return data;
    },
    getPayReservationValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservations');
      if (info) {
        data = parseInt(info);
      }
      return data;
    }
  }
};
</script>
