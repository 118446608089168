<template>
  <div class="flex-container" v-if="experiencePay != 0">
    <div class="fullInfoDecoration">
      <span class="item-left-total">
        <span class="checkout-item-subtitle">{{ $t('experience') }}</span>
        <span class="item-left-total">
          <p class="item-small-total">
            {{ experiencePay | currency("$ ", 0, { thousandsSeparator: "," }) }}&nbsp;
          </p>
        </span>
      </span>
      <p class="item-right-total"> {{ experiencePay | currency("$ ", 0, { thousandsSeparator: "," }) }} </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['experiencePay']
};
</script>
