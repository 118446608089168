<template>
  <svg
    :style="{fill: color, width: size || '1.125rem'}"
    data-name="Group 5821"
    id="Group_5821"
    viewBox="0 0 84.954 35.389"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :style="{fill: color, width: size || '1.125rem'}"
      d="M84.71,25.462,78.858,5.26A7.077,7.077,0,0,0,72.031,0H12.4a1.838,1.838,0,0,0-1.393.662C10.937.731.731,19.436.731,19.436a6.009,6.009,0,0,0,5.26,8.882H16.475l.348,1.533a7.011,7.011,0,0,0,6.9,5.538h.07l54.685-1.776a6.489,6.489,0,0,0,6.479-6.479A5.554,5.554,0,0,0,84.71,25.462Zm-78.719-.7a2.483,2.483,0,0,1-2.473-2.473,2.316,2.316,0,0,1,.313-1.184L11.669,6.722l4.006,18.043ZM78.44,30.094,23.685,31.871a3.469,3.469,0,0,1-3.413-2.787L14.594,3.553H72.031A3.535,3.535,0,0,1,75.445,6.2L81.3,26.4a2.652,2.652,0,0,1,.1.731A2.958,2.958,0,0,1,78.44,30.094Z"
      data-name="Path 6705"
      id="Path_6705"
      transform="translate(0)"
    />
    <path
      :style="{fill: color, width: size || '1.125rem'}"
      d="M118.78,36.492,83.7,37.607a1.759,1.759,0,0,0,.07,3.518h.07l35.11-1.115a1.755,1.755,0,0,0,1.707-1.811A1.793,1.793,0,0,0,118.78,36.492Z"
      data-name="Path 6706"
      id="Path_6706"
      transform="translate(-53.436 -23.779)"
    />
    <path
      :style="{fill: color, width: size || '1.125rem'}"
      d="M123.33,57.592,98.7,58.359a1.777,1.777,0,0,0,.035,3.553h.07l24.626-.766a1.777,1.777,0,0,0-.1-3.553Z"
      data-name="Path 6707"
      id="Path_6707"
      transform="translate(-63.211 -37.53)"
    />
  </svg>
</template>
<script>
export default {
  props: ['color', 'size']
};
</script>
