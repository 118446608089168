<template>
  <div class="modals-bosy">
    <button @click="close()" class="btn-close-modal-plate" type="button">
      <img
        alt="icon point green"
        src="../../assets/images/icons/icon_btn_close.svg"
        style="margin-bottom:-5px"
      />
    </button>
    <div class="columns">
      <div class="column is-4">
        <i class="icon_plate_modal"></i>
      </div>
      <div class="column is-8 size-mobile-tablet">
        <h3 class="title-modal text-aling-left">
          {{$t('chooseMainD')}}
          <br />
          <span
            class="f14 fw-700"
          >{{$t('mainDishe')}} {{ listPlates.length }} {{$t('mainDishe2')}} :</span>
        </h3>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="plate-list-size" v-if="listPlates.length > 0">
        <div
          :key="'list-plates-'+index"
          class="plate-list-padding fw-700"
          v-for="(item,index) in listPlates"
        >
          <strong>{{index + 1}}. {{item.name}}</strong>
          <br />
          <span class="f11 fw-400 block-plate" style="display:block;">{{ item.description }}</span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 text-aling-center border-bottom-plate">
        <span class="f14 fw-700">{{$t('chooseOptionPerson')}}</span>
      </div>
    </div>
    <div v-if="listPersonPlate.length > 0">
      <div class="grip-plate-row">
        <div
          :key="'personList-'+index"
          class="column is-4 plate-padding-bottom"
          v-for="(itemP,index) in listPersonPlate"
        >
          <b-field class="justify-center-plate f14 fw-700" horizontal>
            <label class="font-style-plate">{{'Persona ' + itemP.person}}</label>
            <b-select
              :class="{'custom-select-green':listPersonPlate[index].plate != null}"
              @input="validateSelects()"
              v-model="listPersonPlate[index].plate"
            >
              <option
                :key="'option-plate-'+indexs"
                :value="item.id"
                v-for="(item,indexs) in listPlates"
              >{{ indexs + 1 }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <div class="btns-modal is-centered" style="margin-top: 30px;justify-content: center;">
      <button
        @click="sendDataModal"
        class="full-btn-modal btn-modal-fill btn-agregar-plate"
        style="min-width:30% !important"
        v-if="stateButton"
      >{{$t('add')}}</button>
      <button
        class="full-btn-modal btn-modal-fill btn-agregar-plate btn-disabled-plate"
        v-else-if="!stateButton"
      >{{$t('add')}}</button>
    </div>
    <div class="f18 fw-700" style="margin-top:15px;text-align: center;" v-if="stateButton">
      <img
        alt="icon point green"
        src="../../assets/images/icons/state_select_plates.svg"
        style="margin-bottom:-5px"
      />
      {{$t('selectedDishes')}} {{ listPersonPlate.length }} {{$t('selectedDishes2')}} !
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';

export default {
  props: ['reservation', 'listPlates'],
  data () {
    return {
      listPersonPlate: [],
      count: 0,
      stateButton: false
    };
  },
  methods: {
    createPersons () {
      for (let i = 1; i <= this.reservation.people; i++) {
        this.listPersonPlate.push({
          person: i,
          plate: null
        });
      }
    },
    close () {
      this.$emit('close', true);
      this.$parent.close();
    },
    sendDataModal () {
      this.reservation.mainPlates = this.listPersonPlate;
      window.localStorage.setItem(
        '_reservation',
        btoa(JSON.stringify(this.reservation))
      );
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      this.$parent.close();
    },
    validateSelects () {
      this.count = 0;
      this.listPersonPlate.forEach(element => {
        if (element.plate != null) {
          this.count++;
        }
      });
      if (this.count >= this.listPersonPlate.length) {
        this.stateButton = true;
      }
    }
  },
  mounted () {
    this.createPersons();
  }
};
</script>
<style lang="scss">
.custom-select-green > span > select {
  background-color: #96da40;
}
.custom-select-green > span > select::after {
  border-color: #ffffff;
}
.grip-plate-row {
  display: flex;
  columns: auto 3;
  flex-wrap: wrap;
}
.btn-disabled-plate {
  min-width: 30% !important;
  background-color: #e4e4e4 !important;
  border: 1px solid #e4e4e4 !important;
}
.justify-center-plate {
  align-items: center;
}
.justify-center-plate > .field-label {
  display: none;
}
.font-style-plate > .field-label.is-normal label.label {
  color: var(--color2) !important;
}
.font-style-plate {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-right: 12px;
  width: 100%;
  color: #000;
}
.btn-agregar-plate {
  border-radius: 26px !important;
  min-width: 30% !important;
  font-size: 14px !important;
}
.plate-padding-bottom {
  padding-bottom: 0px !important;
}
.plate-list-size {
  display: flex;
  columns: 3;
  flex-wrap: wrap;
}
.plate-list-padding {
  width: 29%;
  margin-right: 10px;
}
.border-bottom-plate {
  border-bottom: 1px solid;
}
.block-plate {
  display: block;
  width: 100%;
}
.text-aling-left {
  text-align: left !important;
}
.text-aling-center {
  text-align: center !important;
}
.icon_plate_modal {
  display: flex;
  margin: auto;
  mask-repeat: no-repeat !important;
  mask-position: center !important;
  height: 51px;
  width: 52px;
  margin-top: 3px;
  align-items: center;
  justify-content: center;
  mask-size: contain;
  background: black;
  -webkit-mask-image: url(../../assets/images/icons/icon_plate.svg);
  mask-image: url(../../assets/images/icons/icon_plate.svg);
}
.btn-close-modal-plate {
  float: right;
  position: absolute;
  right: 22px;
  top: 18px;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.modal-size-plate > .modal-content {
  max-width: 614px !important;
}
@media screen and (min-width:200px) and (max-width: 768px) {
  .plate-list-size {
    columns: 2;
  }
  .plate-list-padding {
    width: 46%;
  }
  .size-mobile-tablet {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}
</style>
